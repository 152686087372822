const BlockTypes = {
  FUNCTION: 'function',
  CRON_PRE_PROCESS_FILTER: 'CRON_PRE_PROCESS_FILTER',
  CRON_PRE_PROCESS: 'CRON_PRE_PROCESS',
  API: 'api',
  IFBLOCK: 'ifBlock',
  IFGROUP: 'ifGroup',
  COMMENT: 'comment',
  VARIABLE: 'variable',
  RESPONSE: 'response',
  CRON: 'cron',
  PLUG: 'plugin',
  DRY_RUN: 'DRY_RUN',
  TRIGGER: 'trigger',
  CRON_GETTER_CONFIG: 'cron-getter-config'
}

const DataTypes = {
  STRING: 'string',
  NUMBER: 'number',
  OBJECT: 'object'
}

const RequestTypes = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

const HeaderTypes = {
  CONTENT_TYPE: 'content-type',
  MULTIPART_FORM_DATA: 'multipart/form-data',
  APPLICATION_JSON: 'application/json',
  APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded'
}

const IfConditionColor = {
  TRUE: '#006400',
  FALSE: '#b8b8b8',
  ERROR: '#FF7F7F'
}

const WrapKeys = {
  WINDOWS: 'Alt-z',
  MAC: 'Control-z'
}

const MiscTypes = {
  CODE: 'code',
  FLOW: 'flow',
  CONTEXT: 'context',
  JAVASCRIPT: 'javascript',
  JSON: 'json',
  OBJECT: 'object',
  NOT_CHECK: 'notcheck',
  MAKE_COMMENT: 'makeComment',
  PROCESSING: 'processing',
  API_FUNCTION_PLUGIN_COMPONENT: 'apiFunctionPluginComponent',
  EMBED_MODE: 'embed',
  OAUTH_MODE: 'oauth',
  SSO_MODE: 'sso'
}

const sliderTypes = {
  FUNCTION: 'function',
  API: 'api',
  RESPONSE: 'response',
  PLUG: 'plugin',
  TRIGGER: 'trigger',
  SELECTTRIGGER: 'selecttrigger',
  SELECTTRIGGERFORCRON: 'selecttriggerforcron',
  CRON: 'cron',
  WEBHOOK: 'webhook',
  WEBHOOK_ADVANCE_CONFIG: 'webhook-advance-config',
  CRON_GETTER_CONFIG: 'cron-getter-config',
  CRON_ADVANCE_CONFIG: 'cron-advance-config',
  MARKETPLACE: 'marketplace',
  VARIABLE: 'variable',
  DH_FUNCTION: 'DH-Function',
  DH_AUTH2_SLIDER: 'DH-AUTH2-SLIDER',
  DRY_RUN: 'DRY_RUN',
  EMAIL: 'email',
  IFBLOCK: 'ifBlock',
  CONTRIBUTE_ACTION: 'CONTRIBUTE_ACTION'
}

export const CUSTOM_PLUGIN = {
  id: 'CUSTOM_PLUGIN',
  label: 'Custom Plugin'
}

const ApiTypes = {
  EMBED: 'embed',
  TEMPLATE: 'template',
  FLOW: 'flow',
  INTEGRATION: 'integration',
  DEFAULT: 'default'
}

const EmbedVerificationStatus = {
  VERIFIED: 'verified',
  NOT_VERIFIED: 'notVerified',
  VERIFYING: 'verifying'
}

// const Domains = ['flow.viasocket.com', 'dev-flow.viasocket.com', 'localhost']

const ChatBotResponseTypes = {
  EXPRESSION: 'expression',
  PERFORM_API: 'perform_API',
  JSON: 'json',
  WORKFLOW: 'workflow',
  CODE: 'code',
  API: 'api'
}

const Tabnames = {
  PUBLISH: 'published',
  DRAFT: 'draft',
  LOG: 'logs',
  CONFIGURATION: 'configuration',
  SETUP: 'setup'
}

const ModalTypeEnums = {
  EMBEDPROJECTCREATE: 'createEmbedProject',
  TEMPLATECREATE: 'createTemplate',
  AUTHORIZE_ORG: 'authorize_org'
}
const ParamsEnums = {
  orgId: 'orgId',
  flowhitid: 'flowhitid',
  projectId: 'projectId',
  stepId: 'stepId',
  slugName: 'slugName',
  scriptId: 'scriptId',
  tabName: 'tabName',
  pluginId: 'pluginId',
  actionId: 'actionId',
  sectionKey: 'sectionKey',
  sectionId: 'sectionId',
  inviteId: 'inviteId',
  clientId: 'clientId',
  sectionIdOrScriptId: 'sectionIdOrScriptId',
  versionIdOrStepId: 'versionIdOrStepId',
  isPublishedTab: 'isPublishedTab',
  versionId: 'versionId',
  isTemplate: 'isTemplate',
  interfaceId: 'interfaceId',
  isConfiguration: 'isConfiguration',
  isLogs: 'isLogs',
  isSetup: 'isSetup',
  embedding: 'embedding',
  search: 'search',
  serviceId: 'serviceId',
  triggerId: 'triggerId',
  stepName: 'stepName',
  eventId: 'eventId',
  firstServiceId: 'firstServiceId',
  isEmbedUrl: 'isEmbedUrl',
  events: 'events',
  triggerStepId: 'triggerStepId',
  threadId: 'threadId',
  AImessage: 'AImessage',
  dhSectionIndex: 'dhSectionIndex',
  timeFrameForAnalytics: 'timeFrameForAnalytics'
}
const FunctionDummyData = {
  id: '',
  code: '',
  type: 'function',
  title: ''
}
const TemplateSettingEnums = {
  editable: 'editable',
  partialEditable: 'partialEditable',
  disabled: 'disabled',
  onlyNewStep: 'onlyNewStep'
}
const PlanTypes = Object.freeze({
  DEVELOPING_AND_FILLED: 'developing_and_filled',
  DEVELOPING_AND_NOT_FILLED: 'developing_and_not_filled',
  NOT_DEVELOPING_AND_FILLED: 'not_developing_and_filled',
  NOT_DEVELOPING_AND_NOT_FILLED: 'not_developing_and_not_filled'
})

const STEP_OPERATION_STATUS = {
  DELETE: 'DELETE',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DRAFTED: 'DRAFTED'
}
export const LOCAL_NOTFICATION_REQUIRED_FIELDS = {
  address: 'PLUGIN_REQUIRED_FIELDS',
  type: 'Error',
  message: 'Please fill all required fields'
}

export const LOCAL_NOTFICATION_UPDATE = {
  address: 'PLUGIN_UPDATE',
  type: 'Success',
  message: 'Update available',
  funcButtonText: 'Update'
}

export const BRIDGES = {
  ROOT: 'root',
  FLOW: 'flow',
  FUNCTION: 'function',
  PROJECT: 'project',
  API: 'api',
  DHACTION: 'dhAction'
}

export const ALERTTYPES = {
  FRONTEND: 'frontend',
  PLUGIN_SOURCE_ALERTS: 'PLUGIN_SOURCE_ALERTS'
}

export const THUNK_CALL_STATUS = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
  PENDING: 'pending'
}

const SampleContextForDhBasicAuth = {
  authData: {}
}

const SampleContextForDhAuth2 = {
  authData: {
    clientid: '',
    clientsecret: '',
    redirecturl: '',
    Authorization: {
      code: ''
    },
    accesstokencode: {
      access_token: '',
      refresh_token: ''
    },
    testcode: {}
  }
}

const OrgBillingProgramType = Object.freeze({
  promo: 'promo',
  expert: 'expert',
  startup: 'startup',
  subscribe: 'subscribe',
  ngo: 'ngo',
  education: 'education',
  woman: 'woman',
  student: 'student',
  agency: 'agency'
})
const ReservedKeywords = Object.freeze([
  'break',
  'case',
  'catch',
  'class',
  'const',
  'context',
  'continue',
  'debugger',
  'default',
  'delete',
  'do',
  'else',
  'export',
  'extends',
  'finally',
  'for',
  'function',
  'if',
  'import',
  'in',
  'instanceof',
  'new',
  'return',
  'super',
  'switch',
  'this',
  'throw',
  'try',
  'typeof',
  'var',
  'void',
  'while',
  'with',
  'yield',
  'let',
  'enum',
  'await',
  'implements',
  'package',
  'protected',
  'interface',
  'private',
  'public',
  'static',
  'arguments',
  'await',
  'boolean',
  'byte',
  'char',
  'double',
  'final',
  'float',
  'goto',
  'int',
  'long',
  'native',
  'short',
  'synchronized',
  'throws',
  'transient',
  'volatile',
  'true',
  'false'
])

const contextIntialState = { context: { req: { body: {}, query: {} }, res: {}, vals: {} } }

Object.freeze(SampleContextForDhAuth2)
Object.freeze(SampleContextForDhBasicAuth)
Object.freeze(BRIDGES)
Object.freeze(ALERTTYPES)
Object.freeze(BlockTypes)
Object.freeze(DataTypes)
Object.freeze(RequestTypes)
Object.freeze(HeaderTypes)
Object.freeze(MiscTypes)
Object.freeze(IfConditionColor)
Object.freeze(WrapKeys)
Object.freeze(sliderTypes)
Object.freeze(ApiTypes)
Object.freeze(Tabnames)
Object.freeze(ChatBotResponseTypes)
Object.freeze(ModalTypeEnums)
Object.freeze(ParamsEnums)
Object.freeze(TemplateSettingEnums)
Object.freeze(STEP_OPERATION_STATUS)
Object.freeze(THUNK_CALL_STATUS)

export {
  PlanTypes,
  BlockTypes,
  DataTypes,
  RequestTypes,
  HeaderTypes,
  MiscTypes,
  IfConditionColor,
  WrapKeys,
  sliderTypes,
  ApiTypes,
  EmbedVerificationStatus,
  // Domains,
  contextIntialState,
  ChatBotResponseTypes,
  Tabnames,
  ModalTypeEnums,
  ParamsEnums,
  FunctionDummyData,
  TemplateSettingEnums,
  STEP_OPERATION_STATUS,
  SampleContextForDhAuth2,
  SampleContextForDhBasicAuth,
  OrgBillingProgramType,
  ReservedKeywords
}
