import { useNavigate, useOutletContext } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, Skeleton, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import ApiIcon from '@mui/icons-material/Api'
import './PreviewSection.scss'
import JavascriptIcon from '@mui/icons-material/Javascript'
import { MiscTypes, ParamsEnums, THUNK_CALL_STATUS, Tabnames } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import isEqual, { useCustomSelector } from '../../../utils/deepCheckSelector'
import { sendDataToParentInEmbed } from '../../../utils/utilities'
import { saveScriptTemplate, saveScripts } from '../../../store/scripts/scriptsThunk'
import { useFetchActionsTriggers, useFetchPlugins } from '../../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { errorToast } from '../../customToast'
import Templates from '../../sliderLayout/Templates.tsx'
import Breadcrumb from '../../breadcrumb/Breadcrumb.tsx'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import CustomCombinationsComponent from './CustomCombinationsComponent.tsx'
import DefaultCombinations from './DefaultCombinations.tsx'
import PluginSearchBar from '../../projectdashboard/PluginSearchBar.tsx'
import config from '../../../config'
import EnabledFlows from './EnabledFlows.tsx'
import { calculateEnabledServiceObject } from '../../../store/scripts/scriptsSelector'
import DeletedAndPausedFlows from './DeletedAndPausedFlows.tsx'
import SelectedPlugToAddStep from '../../masterSliderComponent/SelectedPlugToAddStep.tsx'
import RenderCombinations from './RenderCombinations.tsx'

type PreviewSectionProps = {
  orgId: string
  projectId: string
  serviceId: string
  integrationServiceId?: string
  serviceTypeFromProps?: 'both' | 'trigger' | 'action'
  showEnabled?: boolean
  showTemplates?: boolean
  showActions?: boolean
  featuredCombinationType?: boolean
  showSubHeading?: boolean
  heading?: string
  authenticationComponent?: () => JSX.Element
  isEmbedUrl?: boolean
}
/**
 * PreviewSection component for displaying integration preview and options.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.orgId - The organization ID
 * @param {string} props.projectId - The project ID
 * @param {string} props.serviceId - The service ID
 * @param {string} [props.integrationServiceId=''] - The integration service ID
 * @param {'both' | 'trigger' | 'action'} [props.serviceTypeFromProps='both'] - The service type
 * @param {boolean} [props.showEnabled=false] - Whether to show enabled services
 * @param {boolean} [props.showTemplates=false] - Whether to show templates
 * @param {boolean} [props.featuredCombinationType=false] - Whether to use featured combination type
 * @param {boolean} [props.showSubHeading=true] - Whether to show sub-heading
 * @param {string} [props.heading=''] - The heading text
 * @param {Function} [props.authenticationComponent=() => null] - The authentication component
 * @param {boolean} [props.isEmbedUrl] - Whether the component is embedded in a URL
 * @returns {JSX.Element} The PreviewSection component
 */

function PreviewSection({
  orgId,
  projectId,
  serviceId,
  integrationServiceId = '',
  serviceTypeFromProps = 'both',
  showEnabled = false,
  templatesHeading,
  showTemplates = false,
  featuredCombinationType = false,
  showSubHeading = true,
  showDeletedFlows = false,
  hideBuiltInTools = false,
  hideApps = false,
  showActions = false,
  heading = '',
  authenticationComponent = () => null,
  isEmbedUrl,
  pagesubheading = '',
  permittedEvents = [],
  unpermittedEvents = []
}: PreviewSectionProps) {
  const context = useOutletContext()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const type = integrationServiceId !== 'webhook' ? 'trigger' : 'action'
  const { currentIntegrationSettings, enabledServiceObject, pluginData, mode } = useCustomSelector((state) => {
    const currentIntegrationSettings = state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings || {}
    return {
      enabledServiceObject: showEnabled
        ? calculateEnabledServiceObject(state, projectId, integrationServiceId, currentIntegrationSettings?.permitsingleflow)
        : {},
      currentIntegrationSettings,
      pluginData: state.allPlugins.pluginData,
      mode: state.appInfo.mode
    }
  })
  const [servicesData, setServicesData] = useState({ pluginsArray: [], pluginsObj: {}, isLoading: false })
  const { pluginsArray, pluginsObj, isLoading } = servicesData

  const [selectedService, setSelectedService] = useState(false)
  const { data: bothServicesJson, isLoading: integrationServiceLoading } = useFetchPlugins(orgId, [], [integrationServiceId, serviceId])

  const integrationService = bothServicesJson?.[integrationServiceId]
  const serviceActionsAndTriggers = useFetchActionsTriggers(integrationServiceId, 'both', permittedEvents)?.data || {}
  const serviceActionsArray = Object.values(serviceActionsAndTriggers)?.filter(
    (action) => !unpermittedEvents.includes(action.rowid) && action.type !== 'trigger'
  )
  const serviceTriggersArray = Object.values(serviceActionsAndTriggers)?.filter(
    (action) => !unpermittedEvents.includes(action.rowid) && action.type === 'trigger'
  )

  useEffect(() => {
    if (!(selectedService === serviceId || selectedService?.rowid === serviceId)) {
      if (!serviceId) setSelectedService(false)
      else if (serviceId === 'function' || serviceId === 'api' || serviceId === 'webhook') setSelectedService(serviceId)
      else {
        const serviceObject = bothServicesJson?.[serviceId] || pluginsObj?.[serviceId] || false
        setSelectedService(serviceObject)
      }
    }
  }, [serviceId, servicesData, integrationServiceLoading])

  const createFlowWithTemplate = async (template) => {
    const templateId = template?.template_id || template?.published_json_script?.identifier
    dispatch(saveScriptTemplate({ projectId, templateId, meta: context?.meta })).then((e) => {
      if (e.payload?.id) {
        sendDataToParentInEmbed('initiated', e.payload)
        createScriptAndNavigate(e.payload.id)
      } else errorToast('Flow creation failed.')
    })
  }
  const createScriptAndNavigate = async (scriptId) => {
    const flowUrl = isEmbedUrl
      ? `/integrations/embed/${orgId}/${projectId}/${serviceId ? `service/${serviceId}/` : ''}workflow/${scriptId}/${Tabnames.DRAFT}`
      : `${config.projectsBaseUrl}/${orgId}/${projectId || `proj${orgId}`}/service/${serviceId || integrationServiceId}${
          config.workflowBaseUrl
        }/${scriptId}/${Tabnames.DRAFT}`
    navigate(flowUrl)
  }
  const handleIntegrationTriggerClick = (type, trigger) => {
    navigate(
      isEmbedUrl
        ? `/integrations/embed/${orgId}/${projectId}/service/${type === 'trigger' ? trigger?.rowid : type}`
        : `/projects/${orgId}${projectId ? `/${projectId}` : ''}/appsexplore/servicepage/${integrationServiceId}/service/${
            type === 'trigger' ? trigger?.rowid : type
          }`
    )
  }

  const createflow = async (triggerEvent, pluginEvent) => {
    const currentProjectId = projectId || `proj${orgId}`
    const triggerEventRowId = triggerEvent?.rowid
    const pluginEventRowId = pluginEvent?.rowid
    let triggerDetails
    if (triggerEventRowId) {
      if (triggerEventRowId === 'webhook')
        triggerDetails = {
          triggerType: 'webhook',
          type: 'add',
          response: {
            data: currentIntegrationSettings?.response?.value || '',
            type: 'response',
            responseType: currentIntegrationSettings?.response?.type
          }
        }
      else
        triggerDetails = {
          eventId: triggerEventRowId
        }
    }
    let functionDetails
    if (['api', 'function']?.includes(pluginEventRowId)) {
      functionDetails = {
        type: pluginEventRowId
      }
    } else if (pluginEventRowId) {
      functionDetails = {
        type: 'plugin',
        eventIdArray: [pluginEventRowId]
      }
    }

    dispatch(
      saveScripts({
        project_id: currentProjectId,
        org_id: orgId,
        script: '//write your code here.',
        triggerDetails,
        functionDetails,
        pluginData,
        title: pluginEvent?.name,
        required: isEmbedUrl ? integrationServiceId : undefined,
        isBasicFlow: isEmbedUrl && integrationServiceId === 'webhook',
        meta: context?.meta
      })
    ).then((e) => {
      const scriptId = e.payload?.id
      if (scriptId && e?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
        sendDataToParentInEmbed('initiated', e.payload)
        createScriptAndNavigate(scriptId)
      } else errorToast('Flow creation failed.')
    })
  }
  const serviceType = useMemo(() => {
    if (integrationServiceId !== 'webhook' && !serviceActionsArray?.length && !serviceTriggersArray?.length) return null
    if (serviceTypeFromProps !== 'both') return serviceTypeFromProps

    if (serviceActionsArray?.length === 0) return 'trigger'
    if (serviceTriggersArray?.length === 0) return 'action'
    return 'both'
  }, [serviceTypeFromProps, serviceActionsArray?.length, serviceTriggersArray?.length])
  const renderServiceButton = (service, onClickHandler, startIcon) => {
    if (service.name === 'API') {
      service = {
        name: 'Custom API',
        description: 'Execute a custom API request.'
      }
    }
    if (service.name === 'Function') {
      service = {
        name: 'Custom Function',
        description: 'Run a custom JavaScript function.'
      }
    }
    return (
      <Box className='p-2 grid-item'>
        <Card variant='outlined' onClick={onClickHandler} className='service-block gap-3 column '>
          <Box className='flex-spaceBetween-center  gap-2  '>
            <Box className='flex-start-center  gap-2 '>
              <Box className='h-100'>
                <IconWrapper component={startIcon} size='32px' />
              </Box>
              <Typography className='service-title '>{service.name}</Typography>
            </Box>
          </Box>

          {service?.description && <Typography className='service-description w-100'>{service?.description}</Typography>}
        </Card>
      </Box>
    )
  }
  const renderAvailableServices = (services) =>
    services.map((service) =>
      renderServiceButton(
        service,
        () => handleIntegrationTriggerClick('trigger', service),
        <img src={service?.iconurl || ''} alt='' width='100%' height='100%' />
      )
    )
  const restrictedDomain = integrationService?.domain
  const areMoreFlowsPossible =
    !Object.keys(currentIntegrationSettings?.permitsingleflow || {})?.length ||
    !isEqual(currentIntegrationSettings?.permitsingleflow, enabledServiceObject?.enabledTriggers)

  const renderList = (title, array, onClickHandler) => {
    if (!areMoreFlowsPossible || !array?.length) return null
    return (
      <Box className='flex flex-col w-full pt-3'>
        <Typography variant='smallHeading' className='px-2'>
          {title}
        </Typography>
        <Box className='container'>
          {array.map((item) => {
            if (enabledServiceObject?.enabledTriggers?.[item.rowid] && currentIntegrationSettings?.permitsingleflow?.[item.rowid])
              return null

            return (
              <Box className='p-2 grid-item' key={item.rowid}>
                <Card
                  variant='outlined'
                  className='service-block service-block__no-description flex-spaceBetween-center gap-2'
                  onClick={() => onClickHandler(item)}
                >
                  <Box className='flex-start-center gap-2'>
                    <IconWrapper iconUrl={item.iconurl} size='32px' />
                    <Typography className='event-box-text'>{item.name}</Typography>
                  </Box>
                </Card>
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }
  return !selectedService ? (
    <Box className='w-100 h-100vh flex-col-center-center overflow-scroll-y p-2 pb-120 pos-rel '>
      <Box className=' h-100 gap-1  flex-col-start-center preview-section-container '>
        {!isEmbedUrl ? <Breadcrumb /> : <Box className='py-2' />}

        <Box className='w-100 px-2 '>
          <Box className='column gap-2'>
            <Typography variant='h4'>{heading}</Typography>
            {integrationServiceId !== 'webhook' && !isEmbedUrl && authenticationComponent(integrationService)}
          </Box>
          {showSubHeading && (
            <Typography>
              {pagesubheading ||
                (integrationServiceLoading ? (
                  <Skeleton />
                ) : (
                  `Enhance your ${integrationService?.name || 'webhook'} experience with a diverse range of powerful
              integrations.`
                ))}
            </Typography>
          )}
        </Box>
        <Box className='column gap-1 w-100 h-100'>
          {featuredCombinationType ? (
            featuredCombinationType === 'custom' ? (
              <CustomCombinationsComponent createflow={createflow} enabledServiceObject={enabledServiceObject} />
            ) : (
              <DefaultCombinations
                createflow={createflow}
                enabledServiceObject={enabledServiceObject}
                firstServiceId={integrationServiceId}
              />
            )
          ) : null}

          {serviceType !== 'action' && renderList('Triggers', serviceTriggersArray, createflow)}

          {serviceType !== 'trigger' &&
            showActions &&
            renderList('Actions', serviceActionsArray, (action) => createflow({ rowid: 'webhook' }, action))}

          {integrationServiceId === 'webhook' && !hideBuiltInTools && (
            <Box className='column w-100 mt-5'>
              <Typography className='w-100 px-2' variant='h6'>
                Built-in Tools
              </Typography>

              <Box className='container '>
                {[
                  { name: 'Custom Function', icon: <JavascriptIcon className='h-100 w-100' />, value: 'function' },
                  { name: 'HTTP/API Request', icon: <ApiIcon className='h-100 w-100' />, value: 'api' }
                  // Add more items here as needed
                ].map((service) => (
                  <Box key={service.value} className='grid-item p-2'>
                    <Card
                      onClick={() => createflow({ rowid: 'webhook' }, { rowid: service.value })}
                      className='service-block gap-2 flex-start-center service-block__no-description'
                    >
                      <IconWrapper component={service.icon} size='24px' />
                      <Typography className='service-title'>{service.name}</Typography>
                    </Card>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {showEnabled && <EnabledFlows firstServiceId={integrationServiceId} />}

          {serviceType && areMoreFlowsPossible && !hideApps && (
            <Box className='p-2 w-100 searchbar-parent  column gap-2'>
              <Typography variant='h6'>Available Apps</Typography>
              <PluginSearchBar
                autoFocus={mode === MiscTypes.EMBED_MODE}
                placeholder='Search services by name, description or domain '
                triggers={['action', 'both']?.includes(serviceType)}
                getServices={({ servicesArray, isLoading: loading, searchQuery }) => {
                  const obj = {}
                  servicesArray = servicesArray
                    .filter((service) => {
                      if (service.rowid === integrationServiceId) return false
                      const triggerExists = Number(service.triggercount)
                      const actionExists = Number(service.actioncount)
                      if (!triggerExists && !actionExists) return false
                      if (!triggerExists && serviceType === 'action') return false
                      if (!actionExists && serviceType === 'trigger') return false

                      return true
                    })
                    ?.slice(0, searchQuery ? undefined : 20)
                  servicesArray.forEach((service) => {
                    obj[service.rowid] = service
                  })
                  setServicesData({
                    isLoading: loading,
                    pluginsArray: servicesArray,
                    pluginsObj: obj
                  })
                }}
              />
            </Box>
          )}
          {isLoading && (
            <Box className='container my-4'>
              {Array.from({ length: 16 }, (_, index) => index).map((el) => {
                return (
                  <Box className='grid-item p-2' key={el}>
                    <Skeleton height='200px' variant='rectangular' width='100%' />
                  </Box>
                )
              })}
            </Box>
          )}
          {serviceType && areMoreFlowsPossible && !hideApps && (
            <Box className='w-100'>
              <Box className='container'>
                {renderAvailableServices(pluginsArray.filter((service) => service.domain !== restrictedDomain))}
              </Box>
            </Box>
          )}

          {showTemplates && <Templates handleTemplateClick={createFlowWithTemplate} templatesHeading={templatesHeading} />}
          {showDeletedFlows && <DeletedAndPausedFlows firstServiceId={integrationServiceId} />}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className='w-100 h-100 flex flex-col items-center p-2 pb-120 overflow-scroll-y pos-rel '>
      <Box className=' h-100 gap-1  flex flex-col preview-section-container '>
        <Breadcrumb />

        {serviceId && (
          <Box className='column w-100 gap-2 px-2 '>
            <Box className='flex-start-center w-100 gap-2'>
              {selectedService?.iconurl && <img src={selectedService?.iconurl || ''} alt='' width='28px  ' />}

              <Typography variant='h4' className='header-iframe-title'>
                {selectedService ? selectedService?.name || '' : serviceId}
              </Typography>
            </Box>

            {!['api', 'function', 'webhook'].includes(serviceId) && !isEmbedUrl && authenticationComponent(selectedService)}
          </Box>
        )}
        {showEnabled && <EnabledFlows firstServiceId={integrationServiceId} />}
        <Box className='w-full flex flex-col'>
          <Typography variant='h6' className='p-2'>
            Recommended Automations
          </Typography>
          {integrationServiceId === 'webhook' ? (
            <SelectedPlugToAddStep
              plug={selectedService}
              addActionFunction={(event) => {
                if (type === 'trigger') createflow(event)
                else createflow({ rowid: 'webhook' }, event)
              }}
              showHeader={false}
              addAIAction={false}
              type={type}
            />
          ) : (
            <Box className='flex flex-col w-full  '>
              <RenderCombinations
                trigger={serviceType !== 'action' ? integrationServiceId : selectedService?.rowid}
                plugin={serviceType !== 'action' ? selectedService?.rowid : integrationServiceId}
                createflow={createflow}
                enabledServiceObject={enabledServiceObject}
              />
              {serviceType === 'both' && (
                <RenderCombinations
                  trigger={selectedService?.rowid}
                  plugin={integrationServiceId}
                  createflow={createflow}
                  enabledServiceObject={enabledServiceObject}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(PreviewSection), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.serviceId,
    ParamsEnums.isEmbedUrl
  ])
)
