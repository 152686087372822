import React, { useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Typography, Box } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CustomDialog from '../../components/customDialog/CustomDialog.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import UpdateTitleOrDescriptionOfAuth from '../../components/common/UpdateTitleOrDescriptionOfAuth.tsx'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import IconWrapper from '../../components/IconWrapper/IconWrapper.tsx'
import { updateAuthDescription } from '../../store/auth/authThunk'

interface AuthenticationSuccessPopUpProps {
  orgId: string
  isEmbedUrl: boolean
}

function AuthenticationSuccessPopUp({ orgId, isEmbedUrl }: AuthenticationSuccessPopUpProps) {
  const { isModalOpen, modalType, dataForModel, iconUrl, currentOrgName } = useCustomSelector((state: $ReduxCoreType) => {
    const dataForModel = state?.appInfo?.dataForModel
    return {
      isModalOpen: state?.appInfo?.isModalOpen,
      modalType: state?.appInfo?.modalType,
      dataForModel,
      currentOrgName: state?.orgs?.orgs?.[orgId]?.name,
      iconUrl: state?.auth?.allAuths?.reduce((acc, item) => {
        if (item?.id === dataForModel?.authId) return item?.iconUrl
        return acc
      }, '')
    }
  })
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const handleToggleAndTitle = (e) => setTitle(e.target.value)
  const handleDoneButton = async () => {
    try {
      dispatch(updateAuthDescription({ orgId, authId: dataForModel?.authId, description: title }))
      setTitle('')
    } finally {
      dispatch(closeModalRedux())
    }
  }

  const authenticationDialogTitle = (
    <Box className='pt-6 flex flex-col items-center gap-2 mb-3'>
      <IconWrapper iconUrl={iconUrl} />
      <Typography variant='h6' className={`mb-2 ${isEmbedUrl ? '!px-6' : ''}`}>
        Your connection has been successfully added!{' '}
      </Typography>

      <CheckCircleRoundedIcon sx={{ fontSize: 40 }} color='success' />
    </Box>
  )

  const ref = useRef(null)
  const authenticationDialogContent = (
    <Box className='bg-[var(--bg-color-dialog-background)] w-full flex flex-col'>
      <Box
        className='authentication-success-modal cursor-pointer border-y !bg-white !flex !flex-col !gap-2 !items-baseline'
        onClick={(e) => {
          e.stopPropagation()
          if (ref.current) ref.current?.focus()
        }}
      >
        <Typography className='w-full'>
          Do you want to add a <span className='font-bold'>title</span> to your connection?
        </Typography>
        <UpdateTitleOrDescriptionOfAuth
          ref={ref}
          handleDoneButton={handleDoneButton}
          value={title}
          handleToggleAndTitle={handleToggleAndTitle}
        />
      </Box>
      {!isEmbedUrl && (
        <Typography className='border-b authentication-success-modal'>
          &nbsp;This connection will be available for the entire workspace - <span className='font-bold'>{`${currentOrgName}`}</span>
        </Typography>
      )}
      <Button className='authentication-success-done-button' variant='contained' onClick={() => handleDoneButton()}>
        Done
      </Button>
    </Box>
  )
  return (
    <>
      <CustomDialog
        open={isModalOpen && modalType === 'authentication'}
        onClose={async (e, reason) => {
          if (reason === 'backdropClick') return
          await handleDoneButton()
        }}
        title={authenticationDialogTitle}
        content={authenticationDialogContent}
        fullWidth={false}
      />
      <Outlet />
    </>
  )
}

export default addUrlDataHoc(React.memo(AuthenticationSuccessPopUp), [ParamsEnums.orgId, ParamsEnums.isEmbedUrl])
