import cloneDeep from 'lodash.clonedeep'
import { errorToast } from '../../components/customToast'
import { fetchOrgs, removeOrgs, saveOrgs, setOrgId, getEmbedTokenThunk, renameOrgThunk } from './orgsThunk'

export const initialState = {
  orgs: {},
  currentOrg: '',
  status: 'idle',
  isLoading: true,
  fetchError: ''
}

let previousOrgs = {}

export const reducers = {
  addOrgInRedux(state, payload) {
    if (payload.payload) {
      const { id } = payload.payload
      state.orgs = { ...state.orgs, [id]: payload.payload }
      state.currentOrg = id
    }
  },
  remove(state) {
    state.orgs = {}
    state.currentOrg = ''
  },
  setId(state, payload) {
    state.currentOrg = payload.payload
  }
}

export function extraReducers(builder) {
  builder
    // save Org
    .addCase(saveOrgs.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(saveOrgs.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      if (payload.payload) {
        const { id } = payload.payload
        state.orgs = { ...state.orgs, [id]: payload.payload }
        state.currentOrg = id
        // successToast('Organization Created Successfully')
      }
    })
    .addCase(saveOrgs.rejected, (state) => {
      state.status = 'failed'
    })
    //   fetch Orgs
    .addCase(fetchOrgs.pending, (state) => {
      state.status = 'loading'
      state.isLoading = true
    })
    .addCase(fetchOrgs.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      // state.fetchError = ''
      const newEntities = {}
      if (payload?.payload?.length) {
        payload.payload?.forEach((org) => {
          const { id } = org
          newEntities[id] = org
        })
      }
      state.orgs = newEntities
      if (state.currentOrg?.length === 0) {
        state.currentOrg = payload?.payload[0]?.id
      }
      state.isLoading = false
      state.fetchError = ''
    })
    .addCase(fetchOrgs.rejected, (state) => {
      state.status = 'failed'
      state.isLoading = false
      state.fetchError = "Oops! We couldn't fetch the data"
    })
    //   remove Org
    .addCase(removeOrgs.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(removeOrgs.fulfilled, (state) => {
      state.status = 'idle'
      state.orgs = {}
      state.currentOrg = ''
    })
    .addCase(removeOrgs.rejected, (state) => {
      state.status = 'failed'
    })
    //   set Org ID
    .addCase(setOrgId.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(setOrgId.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      state.currentOrg = payload.payload
    })
    .addCase(setOrgId.rejected, (state) => {
      state.status = 'failed'
    })

    //  set Embed Org Access Token
    // @author-ArthrajRathore
    .addCase(getEmbedTokenThunk.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(getEmbedTokenThunk.fulfilled, (state, payload) => {
      const newobj = state.orgs?.[payload?.payload?.orgId]
      if (!newobj.meta) newobj.meta = {}
      newobj.meta.auth_token = payload?.payload?.payload
      state.orgs[payload?.payload?.orgId] = newobj
      state.status = 'succeded'
    })
    .addCase(getEmbedTokenThunk.rejected, (state) => {
      state.status = 'failed'
    })
    .addCase(renameOrgThunk.pending, (state, payload) => {
      state.isLoading = true
      previousOrgs = cloneDeep(state.orgs)
      const orgId = payload?.meta?.arg?.orgId
      const title = payload?.meta?.arg?.body?.title
      state.orgs = { ...state.orgs, [orgId]: { ...state.orgs[orgId], title: title } }
    })
    .addCase(renameOrgThunk.fulfilled, (state, payload) => {
      state.isLoading = false
      if (payload.payload) {
        const { id } = payload.payload
        state.orgs = { ...state.orgs, [id]: payload.payload }
        // successToast('Organization Name Updated Successfully')
      }
      previousOrgs = {}
    })
    .addCase(renameOrgThunk.rejected, (state) => {
      state.isLoading = false
      state.orgs = { ...previousOrgs }
      previousOrgs = {}
      errorToast('Failed To Update The Organization')
    })
}
