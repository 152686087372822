import { SimpleTreeView } from '@mui/x-tree-view'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CreateNewProjectButton from '../../AppsExplore/CreateNewProjectButton.tsx'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../../enums'

interface FolderListProps {
  activePauseProject: object
  renderProjectItem(value: any, index: number): React.ReactNode
  deletedFolders: boolean
  projectId: string
}
function FolderList({ activePauseProject, renderProjectItem, deletedFolders = false, projectId }: FolderListProps) {
  return (
    <Box className='flex flex-col w-full gap-2'>
      <Box className='flex items-center justify-between px-1'>
        <Typography variant='body1'>{deletedFolders ? 'Deleted Folders' : 'Folders'}</Typography>
        {deletedFolders ? null : <CreateNewProjectButton />}
      </Box>

      <SimpleTreeView defaultExpandedItems={[projectId]} selectedItems={projectId} className='flex flex-col gap-1'>
        {activePauseProject.map((value, index) => {
          return renderProjectItem(value, index)
        })}
      </SimpleTreeView>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(FolderList), [ParamsEnums.projectId]))
