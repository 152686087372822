import React from 'react'
import { Chip } from '@mui/material'
import { useDispatch } from 'react-redux'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { VariableSizeTree as Tree } from '../React-VTree/index.ts'
import { openModalRedux } from '../../store/appInfo/appInfoSlice.ts'

function rightButtons(value, newPath, showOpenInModel) {
  const handleCopy = (value) => {
    const valuetocopy = typeof value === 'string' ? value : JSON.stringify(value, null, 2)
    if (window.parent !== window) {
      window?.parent?.postMessage({ type: 'copy', message: valuetocopy }, '*')
    } else navigator.clipboard.writeText(valuetocopy)
  }
  const dispatch = useDispatch()
  return (
    // eslint-disable-next-line
    <span
      className='right-button gap-1'
      onMouseDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <Chip
        size='extrasmall'
        className='px-1'
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleCopy(value?.toString())
        }}
        label='Copy Value'
      />

      {showOpenInModel && (
        <Chip
          size='extrasmall'
          className='ml-2 px-1'
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            dispatch(
              openModalRedux({
                modalType: 'showDataForResponseChip',
                dataForModel: {
                  type: 'string',
                  dataToShow: value,
                  titleToShow: newPath
                }
              })
            )
          }}
          label='Show full value'
        />
      )}
    </span>
  )
}

function* treeWalker(data, refresh) {
  const stack = []
  stack.push({ nestingLevel: 0, node: data })
  while (stack.length !== 0) {
    const {
      node: { children = [], id, name, value, pathLocation, valueToCopy, valueToShow, valueOfKeyToShow },
      nestingLevel
    } = stack.pop()
    const isOpened = yield refresh
      ? {
          defaultHeight: 25,
          id,
          isLeaf: children?.length === 0,
          isOpenByDefault: true,
          name,
          nestingLevel,
          pathLocation,
          value,
          valueToCopy,
          valueToShow,
          valueOfKeyToShow
        }
      : id
    if (children.length !== 0 && isOpened) {
      for (let i = children.length - 1; i >= 0; i--) {
        stack.push({
          nestingLevel: nestingLevel + 1,
          node: children[i]
        })
      }
    }
  }
}

// Node component receives current node height as a prop
function Node({ data: { isLeaf, pathLocation, nestingLevel, valueToCopy, valueToShow, valueOfKeyToShow }, isOpen, style, toggle }: any) {
  function getComponentToRender() {
    let style = { whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }
    let content = valueToShow
    if (typeof valueToShow === 'string') {
      style = { ...style }
      content = JSON.stringify(valueToShow)
    } else if (typeof valueToShow === 'boolean') {
      style = { ...style, color: '#d14' }
      content = valueToShow.toString()
    } else if (typeof valueToShow === 'number') {
      style = { ...style, color: '#28b' }
      content = valueToShow.toString()
    } else if (valueToShow === null || valueToShow === undefined) {
      style = { ...style, color: '#666' }
      content = 'null'
    }
    return (
      <div className='flex overflow-hide-x w-100'>
        <div style={style}>{content?.trim()}&nbsp;&nbsp;</div>
        <div>{rightButtons(valueToCopy, pathLocation, typeof valueToShow === 'string' && valueToShow.length > 40)}</div>
      </div>
    )
  }
  return (
    <div key={pathLocation} style={{ ...style, display: 'flex', maxWidth: '100%' }} className='pre-word-wrap list-item'>
      {Array.from({ length: nestingLevel * 2 || 1 }, (_, index) => (
        <div key={`${pathLocation}-${index.toString()}`} style={{ borderRight: index % 2 ? '3px solid #ccc' : null, padding: '0px 5px' }} />
      ))}
      <div className='flex-center pre-word-wrap' style={{ maxWidth: '100%' }}>
        {!isLeaf && (
          <ChevronRightIcon
            onClick={toggle}
            style={{ fontSize: '18px', transform: isOpen ? 'rotate(90deg)' : '', marginLeft: nestingLevel === 0 ? null : '13px' }}
          />
        )}
        &nbsp;&nbsp;
        <span
          style={{
            backgroundColor: 'rgb(229, 228, 226)',
            padding: '2px 6px',
            whiteSpace: 'nowrap',
            margin: isLeaf ? '2px 10px' : '2px 0px'
          }}
        >
          {' '}
          {valueOfKeyToShow}
        </span>
        &nbsp;&nbsp;
        {isLeaf && getComponentToRender()}
        {!isLeaf && (
          <div style={{ color: '#ccc', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {valueToShow}&nbsp;
            {rightButtons(valueToCopy, pathLocation)}
          </div>
        )}
      </div>
    </div>
  )
}

function ReactVTree({ data, height = 400 }) {
  return (
    <Tree treeWalker={(refresh) => treeWalker(data, refresh)} height={height}>
      {(nodeProps) => <Node {...nodeProps} />}
    </Tree>
  )
}

export default React.memo(ReactVTree)
