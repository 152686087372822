import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Typography, TextField, Button, Box, DialogContent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import './formbilling.scss'
import timezoneData, { getLocalTimezone } from '../../components/setting/timezone'
import { saveOrgs } from '../../store/orgs/orgsThunk'
import { validateOrgName } from '../../utils/utilities'
import { errorToast } from '../../components/customToast'
import { ParamsEnums, THUNK_CALL_STATUS } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { closeModalRedux, openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import { selectActiveOrgs } from '../../store/orgs/orgsSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import CustomModal from '../../components/customModal/customModal'
import { ThunkResponseType } from '../../types/types.ts'
import config from '../../config'
import { IndustrySection, TimeZoneSection } from '../../components/setting/WorkspaceSetting.tsx'

interface FormValueObject {
  workspaceName: string
  timeZone: { identifier: string; offSet: string }
  domain: string
}

function CreateOrgModal() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [error, setError] = useState({
    workspace: { error: false, message: '' },
    timezone: {
      error: false,
      message: ''
    }
  })
  const modalType = useCustomSelector((state) => state.appInfo?.modalType)
  const orgList = useCustomSelector(
    (state: $ReduxCoreType) => selectActiveOrgs(state)?.sort((a, b) => a?.name?.localeCompare(b?.name)) || []
  )

  const [orgListVariable, setOrgListVariable] = useState(orgList || [])
  const [formValueObject, setFormValueObject] = useState<FormValueObject>({
    workspaceName: '',
    timeZone: { identifier: '', offSet: '' },
    domain: ''
  })

  const [selectedIndustry, setSelectedIndustry] = useState(null)
  const [preferredIndustryObject, setPreferredIndustryObject] = useState(null)
  const [industries, setIndustries] = useState([])

  const handleTimezoneChange = (newValue: { identifier: string; offSet: string }) => {
    if (newValue) {
      setFormValueObject((prev) => ({
        ...prev,
        timeZone: { identifier: newValue.identifier, offSet: newValue.offSet }
      }))
    }
  }

  const setDynamicTimeZone = () => {
    const currenttimezoneIdentifier = getLocalTimezone()
    const timezone = timezoneData.find((el) => el.identifier === currenttimezoneIdentifier)
    handleTimezoneChange(timezone)
  }

  useEffect(() => {
    // Find the matching timezone data
    setDynamicTimeZone()
    setError(false)
  }, [])
  const [loading, setLoading] = useState(false)
  const createOrg = async () => {
    try {
      setLoading(true)
      setOrgListVariable([...orgListVariable, { id: 'new_workspace' }])
      if (formValueObject.workspaceName.includes(' ')) {
        setError({ ...error, workspace: { error: true, message: 'space not allowed' } })
        return
      }
      if (!formValueObject.workspaceName) {
        if (!formValueObject?.timeZone?.identifier) {
          setError((prev) => ({ ...prev, timezone: { error: true, message: 'Enter a valid timezone' } }))
        }
        setError((prev) => ({ ...prev, workspace: { error: true, message: 'Enter valid workspace name' } }))
        return
      }
      if (!formValueObject?.timeZone?.identifier) {
        setError((prev) => ({ ...prev, timezone: { error: true, message: 'Enter a valid timezone' } }))
        return
      }
      if (!validateOrgName(formValueObject.workspaceName, orgList, setOrgListVariable)) return
      const dataToSend = {
        name: formValueObject.workspaceName,
        timezone: formValueObject.timeZone.offSet,
        meta: {
          domainUrl: formValueObject?.domain || '',
          timezoneIdentifier: formValueObject.timeZone.identifier,
          preferred_industry: preferredIndustryObject
        }
      }
      await dispatch(saveOrgs({ ...dataToSend })).then((data: ThunkResponseType<{ id: string }>) => {
        if (data?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
          // window.location.href = `/activate/${data.payload.id}`
          navigate(`${config.projectsBaseUrl}/${data.payload.id}`)
        } else if (data?.meta?.requestStatus === THUNK_CALL_STATUS.REJECTED) {
          setFormValueObject({ ...formValueObject, workspaceName: '' })
        }
      })
      dispatch(closeModalRedux({}))
    } catch (error) {
      setLoading(false)
      errorToast(error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    dispatch(openModalRedux({ modalType: '' }))
    setFormValueObject((prev) => ({
      ...prev,
      workspaceName: '',
      domain: ''
    }))
    setError({
      workspace: { error: false, message: '' },
      timezone: {
        error: false,
        message: ''
      }
    })
  }

  const IndustrySectionProps = {
    autcompleteTextfieldProps: {
      placeholder: 'Example :- IT'
    },
    IndustrySectionHeadingProps: {
      spanText: '(Optional)'
    }
  }

  return (
    <CustomModal width='25vw' openModal={modalType === 'createOrg'} onClose={handleClose}>
      <DialogContent className='flex flex-col gap-6 !p-9'>
        <Box className='w-full flex flex-col gap-4'>
          <Typography className='font-bold' variant='h6'>
            Enter your workspace details
          </Typography>
          <Box className='flex flex-col gap-4'>
            <Box>
              <Typography>Workspace Name (Short & Unique)</Typography>
              <TextField
                fullWidth
                error={error.workspace}
                helperText={error?.workspace?.error && error?.workspace?.message}
                size='small'
                inputProps={{ maxLength: 25 }}
                className='mt-2 bg-white'
                value={formValueObject.workspaceName}
                onChange={(e) => setFormValueObject({ ...formValueObject, workspaceName: e.target.value })}
                required
                id='outlined-error-helper-text'
                // label="Workspace Name"
                placeholder='Workspace name'
              />
            </Box>
            <TimeZoneSection
              error={error?.timezone}
              timezone={formValueObject?.timeZone}
              handleTimezoneChange={handleTimezoneChange}
              required
            />
            <Box>
              <IndustrySection>
                <IndustrySection.Heading {...IndustrySectionProps.IndustrySectionHeadingProps} />
                <IndustrySection.Autocomplete
                  {...{
                    selectedIndustry,
                    setSelectedIndustry,
                    industries,
                    setIndustries,
                    setPreferredIndustryObject,
                    preferredIndustryObject,
                    autcompleteTextfieldProps: IndustrySectionProps.autcompleteTextfieldProps
                  }}
                />
              </IndustrySection>
            </Box>
            <Box>
              <Typography>
                Domain <span>(Optional)</span>
              </Typography>
              <TextField
                fullWidth
                size='small'
                className='mt-2 bg-white'
                value={formValueObject.domain}
                onChange={(e) => setFormValueObject({ ...formValueObject, domain: e.target.value })}
                placeholder='Your official website address'
              />
            </Box>
            <div className='flex items-center gap-6 mt-auto justify-end'>
              <Button variant='outlined' onClick={handleClose}>
                Close
              </Button>
              <LoadingButton variant='contained' className='flex justify-end' onClick={createOrg} loading={loading}>
                Create
              </LoadingButton>
            </div>
          </Box>
        </Box>
      </DialogContent>
    </CustomModal>
  )
}

export default React.memo(addUrlDataHoc(React.memo(CreateOrgModal), [ParamsEnums.orgId]))
