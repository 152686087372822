import axios from 'axios'

interface CleanApiOptions {
  maxStringLength?: number
  maxDataSizeKB?: number
  hugeSizeMessage?: string
  valuePlaceholder?: string
  maxDepth?: number
}

function getUniqueObjects(arr: any[]): any[] {
  const seen = new Set()

  return arr.filter((item) => {
    if (item === null) return true // Handle null explicitly

    if (typeof item !== 'object') {
      const primitiveKey = `${typeof item}:${item}`
      return seen.has(primitiveKey) ? false : seen.add(primitiveKey)
    }

    try {
      // Handle circular references
      const key = JSON.stringify(Object.keys(item).sort())
      return seen.has(key) ? false : seen.add(key)
    } catch (err) {
      // If stringification fails, treat as unique
      return true
    }
  })
}

// Improved size calculation with safety checks
const getDataSizeKB = (data: any): number => {
  try {
    if (typeof data === 'undefined') return 0
    if (data === null) return 0.001 // Minimal size for null

    return new TextEncoder().encode(typeof data === 'string' ? data : JSON.stringify(data)).length / 1024
  } catch (err) {
    return 0
  }
}

function cleanApiResponse(rowData: any, options: CleanApiOptions = {}): any {
  const {
    maxStringLength = 40,
    maxDataSizeKB = 4,
    hugeSizeMessage = 'huge data',
    valuePlaceholder = 'too large value',
    maxDepth = 10
  } = options

  // Add safety check for input
  if (rowData === null || typeof rowData === 'undefined') {
    return rowData
  }

  function clean(data: any, depth = 0): any {
    try {
      if (depth > maxDepth) return '[reached max depth]'

      // Handle primitives and null
      if (!data || typeof data !== 'object') {
        if (typeof data === 'string' && data.length > maxStringLength) {
          return valuePlaceholder
        }
        // Handle special number cases
        if (typeof data === 'number') {
          if (!Number.isFinite(data)) return 0
          return data
        }
        return data
      }

      // Handle arrays
      if (Array.isArray(data)) {
        if (data.length === 0) return []
        const cleanedArray = getUniqueObjects(data).map((item) => clean(item, depth + 1))
        return cleanedArray
      }

      // Handle objects
      const objEntries = Object.entries(data).map(([key, value]) => {
        const cleanedValue = clean(value, depth + 1)
        return cleanedValue !== undefined ? [key, cleanedValue] : null
      })

      return Object.fromEntries(objEntries as [string, any][])
    } catch (err) {
      return `Error: ${err}`
    }
  }

  try {
    const finalizedData = clean(rowData)
    return getDataSizeKB(finalizedData) > maxDataSizeKB ? hugeSizeMessage : finalizedData
  } catch (err) {
    return `Error: ${err}`
  }
}
export const MiddlewareBridge = async (data: any) => {
  try {
    const standardizedResponse = cleanApiResponse(data.user, {
      maxStringLength: 100,
      maxDataSizeKB: 4,
      hugeSizeMessage: 'data too large',
      valuePlaceholder: 'Big data',
      maxDepth: 10
    })
    const updatedData = { ...data, user: JSON.stringify(standardizedResponse) }
    await axios.post('https://proxy.viasocket.com/proxy/api/1258584/29gjrmh24/api/v2/model/chat/completion', updatedData, {
      headers: {
        pauthkey: '6e2105feb053c87e5f6fef61cf882c23',
        'Content-Type': 'application/json'
      },
      maxBodyLength: Infinity
    })
  } catch (error) {
    console.error('Middleware Error:', error)
    // throw error
  }
}
