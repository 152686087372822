// import {saveAuth } from './authThunk';

import { getAllAuth, updateAuthDescription } from './authThunk'

export const initialState = {
  status: 'idle',
  auths: {},
  currentAuth: {},
  allAuths: [],
  isLoading: false,
  error: null
}

export const reducers = {
  addCurrentAuth(state, payload) {
    state.currentAuth = payload.payload
  },
  updateExistingAuth(state, { payload }) {
    const { authId, description } = payload
    const authIndex = state.allAuths?.findIndex((auth) => auth?.id === authId)
    if (authIndex !== -1) {
      state.allAuths[authIndex].meta_data.description = description
    }
  },
  addNewAuth(state, { payload }) {
    const { id } = payload
    const authIndex = state.allAuths?.findIndex((auth) => auth?.id === id)
    if (authIndex === -1) {
      state.allAuths = [...state.allAuths, payload]
    }
  },
  deleteAnAuth(state, { payload }) {
    if (payload) state.allAuths = state.allAuths?.filter((auth) => auth?.id !== payload)
  }
}

export function extraReducers(builder) {
  builder
    .addCase(getAllAuth.pending, (state) => {
      state.isLoading = true
      state.error = null
    })

    .addCase(getAllAuth.fulfilled, (state, action) => {
      state.isLoading = false
      state.allAuths = action.payload
    })

    .addCase(getAllAuth.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
    .addCase(updateAuthDescription.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    .addCase(updateAuthDescription.fulfilled, (state) => {
      state.isLoading = false
    })
    .addCase(updateAuthDescription.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
