import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import Protected from './components/protected'
import WithAuth from './components/withAuth'
import './App.css'
import './scss/global.scss'
import config from './config'
import { generateTheme } from './MuiTheme'
import Login from './pages/login/login'
import ProjectAndOrgUrlParams from './components/urlParameter/projectAndOrgUrlParams.tsx'
import { Tabnames } from './enums'
import CustomTableSkeleton from './components/skeleton/invitePageSkeleton'
import FlowPageSkeleton from './components/skeleton/flowPageSkeleton'
import AuthDataTableSkeleton from './components/skeleton/authDataTableSkeleton'
import WorkspaceSkeleton from './components/skeleton/workspaceSkeleton'
import EmbedWrapper from './components/embed/EmbedWrapper.tsx'
import EmbedError from './components/embed/embedError'
import PreviewSectionWrapper from './components/embededPageComponents/IntegrationSliderLayout/PreviewSectionWrapper.tsx'
import './utils/RtLayerClient'
import './utils/AuthTokenSetter'
import ServicePage from './pages/addNewAuth/ServicePage.tsx'
import EventPage from './pages/addNewAuth/EventPage.tsx'
import AuthPage from './pages/addNewAuth/authPage.tsx'
import ProjectSliderComponent from './components/sliderLayout/projectSliderLayoutV2'
import SliderLayoutV2 from './components/sliderLayout/sliderLayoutV2'
import DiscordLogin from './pages/discord-login/discordLoginPage'
import ServicePageRedirector from './ServicePageRedirector.tsx'
import FrillLogin from './pages/frill-login/frillLoginPage'
import DesignSystem from './pages/designSystemPage/DesignSystem'
import AuthWrapper from './AuthWrapper.tsx'
import NewTemplateslider from './NewTemplateslider.tsx'
import AuthenticationSuccessPopUp from './pages/authDataTable/AuthenticationSuccessPopUp.tsx'
import ForbiddenErrorPage from './ForbiddenErrorPage.tsx'
import ThirdPartyIntegrations from './thirdPartyIntegrations.tsx'

const AuthCongratulationScreen = React.lazy(() => import('./pages/addNewAuth/congratulationsScreen.tsx'))
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard.tsx'))
const NewDashboard = React.lazy(() => import('./pages/dashboard/NewDashboard.tsx'))
const IntegrationSliderLayout = React.lazy(() =>
  import('./components/embededPageComponents/IntegrationSliderLayout/IntegrationSliderLayout.tsx')
)
const IntegrationPage = React.lazy(() => import('./components/embededPageComponents/IntegrationSliderLayout/IntegrationPage.tsx'))
const OauthIntegration = React.lazy(() => import('./pages/oauth/oauthIntegration'))
const OauthConfig = React.lazy(() => import('./pages/oauth/oauthConfig'))
const Createflowselectplacemodal = React.lazy(() => import('./createflowselectplacemodal.tsx'))
const AfterSignupPage = React.lazy(() => import('./pages/aftersignup/aftersignup.tsx'))
const FormBillingSecondForm = React.lazy(() => import('./pages/formBillingSecondPage/formBillingSecondPage.tsx'))
const SuccessAfter = React.lazy(() => import('./pages/successafter/successafter.tsx'))
const IntegrationPreviewComponent = React.lazy(() =>
  import('./components/embededPageComponents/IntegrationSliderLayout/IntegrationPreviewComponent.tsx')
)
const SettingsPanel = React.lazy(() => import('./components/setting/SettingsPanel.tsx'))
const MasterSliderComponent = React.lazy(() => import('./components/masterSliderComponent/masterSliderComponent.tsx'))
const ServiceGallery = React.lazy(() => import('./components/AppsExplore/ServiceGallery.tsx'))
const Oauth = React.lazy(() => import('./pages/oauth/oauth'))
const Notfoundpage = React.lazy(() => import(/* webpackChunkName: "notfoundpage" */ './pages/pageNotFound/notFoundPage'))
const InvitePage = React.lazy(() => import(/* webpackChunkName: "invitepage" */ './pages/invitePage/invitePage'))
const FlowPageV2 = React.lazy(() => import(/* webpackChunkName: "flowpagev2" */ './pages/flowPage/flowPageV2'))
const Embed = React.lazy(() => import(/* webpackChunkName: "embed" */ './components/embed/embed'))
const AuthData = React.lazy(() => import(/* webpackChunkName: "authdata" */ './pages/authDataTable/authDataTable'))
const SSO = React.lazy(() => import(/* webpackChunkName: "sso" */ './components/sso/sso'))
const Storage = React.lazy(() => import(/* webpackChunkName: "storage" */ './pages/storagePage'))
const Viewonlygrid = React.lazy(() => import(/* webpackChunkName: "viewonlygrid" */ './pages/interface/components/Grid/Viewonlygrid.tsx'))
const OauthAuthorization = React.lazy(() =>
  import(/* webpackChunkName: "oauthauthorization" */ './components/oauthAuthorization/OauthAuthorization')
)
const WorkspacesComponent = React.lazy(() =>
  import(/* webpackChunkName: "workspacescomponent" */ './components/workspaceComponent/WorkspacesComponent.tsx')
)

// Developer Hub Routes
const DeveloperHubLayout = React.lazy(() =>
  import(/* webpackChunkName: "developerhublayout" */ './components/sliderLayout/developerHubLayout')
)
const DeveloperHubUrlParams = React.lazy(() =>
  import(/* webpackChunkName: "developerhuburlparams" */ './components/urlParameter/developerHubUrlParams.tsx')
)
// const DeveloperHubRoutes = React.lazy(() => import(/* webpackChunkName: "developerhubroutes" */ './pages/developerHub/developerHubRoutes'))
const DynamicSection = React.lazy(() =>
  import(/* webpackChunkName: "dynamicSection" */ './pages/developerHub/components/DynamicSection/DynamicSection.tsx')
)

// Interface Routes
const Interface = React.lazy(() => import(/* webpackChunkName: "interface" */ './pages/interface/interface.tsx'))
const InterfaceSetupPage = React.lazy(() =>
  import(/* webpackChunkName: "interfacesetuppage" */ './pages/interface/components/InterfaceConfiguration/interfaceConfigSetup.tsx')
)

// Oauth Routes
const OAuth2Page = React.lazy(() => import(/* webpackChunkName: "oauthpage" */ './components/OauthPage/OauthPage'))

const TransferOptionPage = React.lazy(() => import('./pages/transferOptionPage/transferOptionPage.tsx'))
const Metrics = React.lazy(() => import('./pages/Metrics/Metrics'))

const WorkflowRoutesEmbed = [
  '/integrations/embed/:orgId/:projectId/service/:serviceId/workflow/:scriptId/:tabName',
  '/integrations/embed/:orgId/:projectId/event/:eventId/service/:serviceId/workflow/:scriptId/:tabName',
  '/integrations/embed/:orgId/:projectId/workflow/:scriptId/:tabName'
]
const WorkflowRoutesFlow = [
  `${config.projectsBaseUrl}/:orgId/:projectId/service/:serviceId${config.workflowBaseUrl}/:scriptId/:tabName`,
  `${config.projectsBaseUrl}/:orgId/:projectId${config.workflowBaseUrl}/:scriptId/:tabName`
]

const OnboardingPage = React.lazy(() => import('./components/OnboardingComponent/onboardingPage'))
const RedirectionPage = React.lazy(() => import('./components/OnboardingComponent/Redirection'))
function App() {
  const {
    projectsBaseUrl,
    orgBaseUrl,
    workflowBaseUrl,
    developerBaseUrl,
    redirectBaseUrl,
    authBaseUrl,
    ssoBaseUrl,
    authCongoPage,
    oauth,
    verficationEmbed,
    servicePage
  } = config

  // const location = useLocation()

  // React.useEffect(() => {
  //   // Add the script when not on the '/' route and the script isn't already added
  //   if (location.pathname !== '/' && location.pathname !== '/sso' && !document.getElementById('signals-script')) {
  //     ;(function () {
  //       if (typeof window === 'undefined') return
  //       if (typeof window.signals !== 'undefined') return

  //       const script = document.createElement('script')
  //       script.id = 'signals-script'
  //       script.src = 'https://cdn.cr-relay.com/v1/site/8159c31a-0d49-4a8b-9319-f945bbb5c4cf/signals.js'
  //       script.async = true

  //       window.signals = window.signals || []
  //       const methods = ['page', 'identify', 'form']
  //       methods.forEach((method) => {
  //         window.signals[method] = (...args) => {
  //           window.signals.push([method, args])
  //           return window.signals
  //         }
  //       })

  //       script.onload = () => {
  //         console.log('Signals script loaded successfully.')
  //       }

  //       document.head.appendChild(script)
  //     })()
  //   }

  //   // Cleanup script when the route is '/'
  //   return () => {
  //     if (location.pathname === '/' || location.pathname === '/sso') {
  //       const script = document.getElementById('signals-script')
  //       if (script) {
  //         document.head.removeChild(script) // Remove the script
  //       }
  //     }
  //   }
  // }, [location])

  return (
    <div>
      <div>
        <ThirdPartyIntegrations />
        {/* <CheckOrgBillingStatus /> */}
      </div>
      <ThemeProvider theme={generateTheme(Tabnames.DRAFT)}>
        <Routes>
          <Route path='/' element={<AuthenticationSuccessPopUp />}>
            <Route
              exact
              path='/'
              element={
                <WithAuth>
                  <Login />
                </WithAuth>
              }
            />
            <Route
              exact
              path='/onboarding'
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Protected>
                    <OnboardingPage />
                  </Protected>
                </React.Suspense>
              }
            />
            <Route
              exact
              path='/org/redirect'
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Protected>
                    <RedirectionPage />
                  </Protected>
                </React.Suspense>
              }
            />
            <Route
              exact
              path='/connect/:serviceId'
              element={
                <Protected>
                  <ServicePageRedirector />
                </Protected>
              }
            />
            <Route
              exact
              path='/servicepage/:serviceId'
              element={
                <Protected>
                  <ServicePageRedirector />
                </Protected>
              }
            />

            <Route exact path='/forbidden' element={<ForbiddenErrorPage />} />

            <Route exact path='/embed/error' element={<EmbedError />} />

            <Route
              exact
              path='/makeflow/trigger/:triggerId/action'
              element={
                <Protected>
                  <React.Suspense fallback='Loading....'>
                    <Createflowselectplacemodal />
                  </React.Suspense>
                </Protected>
              }
            />
            <Route
              exact
              path='/activate/:orgId' // Define the route for the "aftersignup" page
              element={
                <Protected>
                  <React.Suspense fallback='Loading....'>
                    <AfterSignupPage />
                  </React.Suspense>
                </Protected>
              } // Render the "aftersignup" page component
            />

            <Route
              exact
              path='/activate/:orgId/billing' // Define the route for the "aftersignup" page
              element={
                <Protected>
                  <React.Suspense fallback='Loading....'>
                    <FormBillingSecondForm />
                  </React.Suspense>
                </Protected>
              } // Render the "aftersignup" page component
            />
            <Route
              exact
              path='/activate/:orgId/success' // Define the route for the "aftersignup" page
              element={
                <Protected>
                  <React.Suspense fallback='Loading....'>
                    <SuccessAfter />
                  </React.Suspense>
                </Protected>
              } // Render the "aftersignup" page component
            />
            <Route
              exact
              path='/connections'
              element={
                <Protected>
                  <ServicePageRedirector />
                </Protected>
              }
            />

            <Route
              exact
              path={`${projectsBaseUrl}/:orgId/:projectId${workflowBaseUrl}/:scriptId/:tabName/slider`}
              element={
                <React.Suspense fallback='Loading....'>
                  <MasterSliderComponent />
                </React.Suspense>
              }
            />
            <Route exact path='/designsystem' element={<DesignSystem />} />
            <Route
              exact
              path={orgBaseUrl}
              element={
                <React.Suspense fallback={<WorkspaceSkeleton />}>
                  <Protected>
                    <WorkspacesComponent />
                  </Protected>
                </React.Suspense>
              }
            />
            <Route
              exact
              path='/auth/storage'
              element={
                <React.Suspense fallback='Loading....'>
                  <Storage />
                </React.Suspense>
              }
            />
            <Route exact path='/integrations/embed' element={<EmbedWrapper />}>
              <Route exact path='/integrations/embed/:orgId/:projectId' element={<PreviewSectionWrapper />}>
                <Route exact path='/integrations/embed/:orgId/:projectId/service/:serviceId' />
              </Route>

              {/* flow routes in embed */}
              {WorkflowRoutesEmbed.map((path) => (
                <Route
                  exact
                  key={path}
                  path={path}
                  element={
                    <React.Suspense fallback={<FlowPageSkeleton />}>
                      <Protected>
                        <FlowPageV2 />
                      </Protected>
                    </React.Suspense>
                  }
                />
              ))}
              {/* transfer option routes in embed */}

              {WorkflowRoutesEmbed.map((path) => {
                path += '/transfer'
                return (
                  <Route
                    exact
                    key={path}
                    path={path}
                    element={
                      <React.Suspense fallback='Loading....'>
                        <Protected>
                          <TransferOptionPage />
                        </Protected>
                      </React.Suspense>
                    }
                  />
                )
              })}
            </Route>
            <Route
              exact
              path='/integrations/:orgId/:projectId/previewwindow'
              element={
                <React.Suspense fallback='Loading....'>
                  <IntegrationPreviewComponent />
                </React.Suspense>
              }
            />

            <Route
              exact
              path='/'
              element={
                <>
                  <ProjectAndOrgUrlParams />
                  <ProjectSliderComponent />
                </>
              }
            >
              <Route
                exact
                path={`${projectsBaseUrl}/:orgId`}
                element={
                  <Protected>
                    <React.Suspense fallback='Loading....'>
                      <NewDashboard />
                    </React.Suspense>
                  </Protected>
                }
              />
              <Route
                exact
                path={`${projectsBaseUrl}/:orgId/dashboard`}
                element={
                  <Protected>
                    <React.Suspense fallback='Loading....'>
                      <Dashboard />
                    </React.Suspense>
                  </Protected>
                }
              />
              <Route exact path={`${orgBaseUrl}/:orgId/notification`} />
              <Route
                exact
                path={`${orgBaseUrl}/:orgId/oauth`}
                element={
                  <Protected>
                    <React.Suspense fallback='Loading....'>
                      <Oauth />
                    </React.Suspense>
                  </Protected>
                }
              />
              <Route
                exact
                path={`${orgBaseUrl}/:orgId/oauth/oauthIntegration`}
                element={
                  <Protected>
                    <React.Suspense fallback='Loading....'>
                      <OauthIntegration />
                    </React.Suspense>
                  </Protected>
                }
              />
              <Route
                exact
                path={`${orgBaseUrl}/:orgId/oauth/oauthConfig/:client_id`}
                element={
                  <Protected>
                    <React.Suspense fallback='Loading....'>
                      <OauthConfig />
                    </React.Suspense>
                  </Protected>
                }
              />
              <Route
                exact
                path='/integrations/:orgId'
                element={
                  <React.Suspense fallback='Loading....'>
                    <IntegrationSliderLayout />
                  </React.Suspense>
                }
              >
                <Route exact path='/integrations/:orgId/:projectId' />
                <Route
                  exact
                  path='/integrations/:orgId/:projectId/:sectionKey'
                  element={
                    <React.Suspense fallback='Loading....'>
                      <IntegrationPage />
                    </React.Suspense>
                  }
                />
              </Route>
              <Route
                exact
                path={`${orgBaseUrl}/:orgId/metrics`}
                element={
                  <React.Suspense fallback='Loading....'>
                    <Metrics />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path={`${authBaseUrl}/:orgId`}
                element={
                  <React.Suspense fallback={<AuthDataTableSkeleton />}>
                    <AuthData />
                  </React.Suspense>
                }
              />
              <Route exact path={`${projectsBaseUrl}`} element={<SliderLayoutV2 />}>
                <Route
                  exact
                  path={`${projectsBaseUrl}/:orgId`}
                  element={
                    // <CustomSuspense>
                    <ProjectAndOrgUrlParams />
                    // </CustomSuspense>
                  }
                />
                <Route
                  exact
                  path={`${projectsBaseUrl}/:orgId/:projectId`}
                  element={
                    <>
                      <ProjectAndOrgUrlParams />
                      <React.Suspense fallback='Loading....'>
                        <NewDashboard />
                      </React.Suspense>
                    </>
                  }
                />

                {['/projects/:orgId/:projectId/appsexplore/servicepage', '/projects/:orgId/appsexplore/servicepage'].map((url) => {
                  return (
                    <Route
                      exact
                      path={url}
                      key={url}
                      element={
                        <React.Suspense fallback='Loading....'>
                          <ServiceGallery />
                        </React.Suspense>
                      }
                    />
                  )
                })}
                {[
                  '/projects/:orgId/:projectId/appsexplore/servicepage/:firstServiceId',
                  '/projects/:orgId/appsexplore/servicepage/:firstServiceId',
                  '/projects/:orgId/:projectId/appsexplore/servicepage/:firstServiceId/service/:serviceId',
                  '/projects/:orgId/appsexplore/servicepage/:firstServiceId/service/:serviceId'
                ].map((url) => {
                  return <Route exact path={url} key={url} element={<PreviewSectionWrapper />} />
                })}

                {/* routes for all apps explore */}

                <Route
                  exact
                  path='/projects/:orgId/:projectId/:interfaceId'
                  element={
                    <React.Suspense fallback='Loading....'>
                      <Interface />
                    </React.Suspense>
                  }
                />
                <Route exact path='/projects/:orgId/:projectId/service/:serviceId' />
                <Route exact path='/projects/:orgId/:projectId/service/:serviceId/createflow/:eventId' />

                {/* flow routes in embed */}
                {WorkflowRoutesFlow.map((path) => (
                  <Route
                    exact
                    key={path}
                    path={path}
                    element={
                      <React.Suspense fallback={<FlowPageSkeleton />}>
                        <Protected>
                          <FlowPageV2 />
                        </Protected>
                      </React.Suspense>
                    }
                  />
                ))}
                {WorkflowRoutesFlow.map((path) => {
                  path += '/transfer'
                  return (
                    <Route
                      exact
                      key={path}
                      path={path}
                      element={
                        <React.Suspense fallback='Loading....'>
                          <Protected>
                            <TransferOptionPage />
                          </Protected>
                        </React.Suspense>
                      }
                    />
                  )
                })}
                <Route
                  exact
                  path='/projects/:orgId/:projectId/:interfaceId/interfaceSetup'
                  element={
                    <React.Suspense fallback='Loading....'>
                      <Protected>
                        <InterfaceSetupPage />
                      </Protected>
                    </React.Suspense>
                  }
                />
              </Route>
              <Route
                path={`${developerBaseUrl}/:orgId`}
                element={
                  <React.Suspense fallback='Loading....'>
                    <DeveloperHubUrlParams />
                  </React.Suspense>
                }
              >
                <Route
                  exact
                  path={`${developerBaseUrl}/:orgId`}
                  element={
                    <React.Suspense fallback='Loading....'>
                      <DeveloperHubLayout />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path={`${developerBaseUrl}/:orgId/plugin/:pluginId`}
                  element={
                    <React.Suspense fallback='Loading....'>
                      <Protected>
                        <DeveloperHubLayout />
                      </Protected>
                    </React.Suspense>
                  }
                >
                  <Route
                    exact
                    path={`${developerBaseUrl}/:orgId/plugin/:pluginId/:sectionKey`}
                    element={
                      <React.Suspense fallback='Loading....'>
                        <Protected>
                          <DynamicSection />
                        </Protected>
                      </React.Suspense>
                    }
                  />
                  <Route
                    exact
                    path={`${developerBaseUrl}/:orgId/plugin/:pluginId/:sectionKey/:sectionId`}
                    element={
                      <React.Suspense fallback='Loading....'>
                        <Protected>
                          <DynamicSection />
                        </Protected>
                      </React.Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                exact
                path={`${orgBaseUrl}/:orgId/setting`}
                element={
                  <Protected>
                    <React.Suspense fallback='Loading....'>
                      <SettingsPanel />
                    </React.Suspense>
                  </Protected>
                }
              />
              <Route
                exact
                path={`${orgBaseUrl}/:orgId/invite`}
                element={
                  <React.Suspense fallback={<CustomTableSkeleton />}>
                    <Protected>
                      <InvitePage />
                    </Protected>
                  </React.Suspense>
                }
              />
              <Route
                exact
                path={`${orgBaseUrl}/:orgId/templates`}
                element={
                  <Protected>
                    <NewTemplateslider />
                  </Protected>
                }
              />
            </Route>
            <Route
              exact
              path={`${developerBaseUrl}/:orgId/plugin/:pluginId/:sectionKey/:sectionId/transfer`}
              element={
                <React.Suspense fallback='Loading....'>
                  <Protected>
                    <TransferOptionPage />
                  </Protected>
                </React.Suspense>
              }
            />
            <Route
              exact
              path='*'
              element={
                <React.Suspense fallback='Loading....'>
                  <Notfoundpage />
                </React.Suspense>
              }
            />
            <Route
              exact
              path={`${redirectBaseUrl}/auth2.0/:clientId`}
              element={
                <React.Suspense fallback='Loading....'>
                  <OAuth2Page />
                </React.Suspense>
              }
            />
            <Route
              exact
              path={`${redirectBaseUrl}/auth1`}
              element={
                <React.Suspense fallback='Loading....'>
                  <OAuth2Page />
                </React.Suspense>
              }
            />
            <Route
              exact
              path={`${redirectBaseUrl}/auth2.0`}
              element={
                <React.Suspense fallback='Loading....'>
                  <OAuth2Page />
                </React.Suspense>
              }
            />
            {/* EMBEDING ROUTES */}
            <Route
              exact
              path={`${verficationEmbed}`}
              element={
                <React.Suspense fallback='Loading....'>
                  <Embed />
                </React.Suspense>
              }
            />
            {/* SSO ROUTES */}
            <Route
              exact
              path={`${ssoBaseUrl}`}
              element={
                <React.Suspense fallback='Loading....'>
                  <SSO />
                </React.Suspense>
              }
            />
            <Route exact path='/' element={<AuthWrapper />}>
              <Route
                exact
                path={`${servicePage}`}
                element={
                  // <Protected>
                  <ServicePage />
                  // </Protected>
                }
              />
              <Route
                exact
                path={`${servicePage}/:serviceId`}
                element={
                  // <Protected>
                  <EventPage />
                  // </Protected>
                }
              />

              <Route
                exact
                path={`${servicePage}/:serviceId/auth/:authId`}
                element={
                  // <Protected>
                  <AuthPage />
                  // </Protected>
                }
              />
            </Route>
            <Route
              exact
              path='/i/:interfaceId'
              element={
                <div id='parent-view-only-grid' className='h-100vh w-100'>
                  <React.Suspense fallback='Loading....'>
                    <Viewonlygrid />
                  </React.Suspense>
                </div>
              }
            />
            <Route
              exact
              path={`${authCongoPage}`}
              element={
                <Protected>
                  <React.Suspense fallback='Loading....'>
                    <AuthCongratulationScreen />
                  </React.Suspense>
                </Protected>
              }
            />

            {/* Route for Get Code */}
            <Route
              exact
              path={oauth}
              element={
                <Protected>
                  <React.Suspense fallback='Loading....'>
                    <OauthAuthorization />
                  </React.Suspense>
                </Protected>
              }
            />
            <Route exact path='/discord-login' element={<DiscordLogin />} />
            <Route exact path='/frill-login' element={<FrillLogin />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  )
}
export default React.memo(App)
