/* eslint-disable no-restricted-syntax */
import cloneDeep from 'lodash.clonedeep'
import {
  saveScripts,
  fetchScripts,
  removeScripts,
  updateScript,
  changeScriptStatus,
  moveScript,
  duplicateScript,
  saveScriptTemplate,
  fetchNameDescriptionByAi
} from './scriptsThunk'
import { BlockTypes } from '../../enums'

export const initialState = {
  projects: {},
  scripts: {},
  dryRunResponseForScript: '',
  status: 'idle',
  isLoading: true,
  fetchError: '',
  orderOfScriptsLastUpdated: []
}

let previousScripts = {}
export const reducers = {
  addInAppIntegration(state, payload) {
    const { serviceId, serviceName, iconUrl, projectId } = payload.payload
    if (!state.projects[projectId]) state.projects[projectId] = { appIntegrations: {} }
    state.projects[projectId].appIntegration = {
      ...state?.projects?.[projectId]?.appIntegration,
      [serviceId]: {
        usedAsTrigger: { active: [], pause: [], delete: [] },
        serviceName,
        iconUrl
      }
    }
  },
  addScriptInRedux(state, payload) {
    if (payload.payload) {
      const { id } = payload.payload
      state.scripts = { ...state.scripts, [id]: payload.payload }
    }
  },

  addScriptAndUpdateList(state, payload) {
    if (payload.payload) {
      const { id, project_id } = payload.payload
      state.scripts = { ...state.scripts, [id]: payload.payload }
      deleteValue(state?.projects?.[project_id], id)
      updateOrderOfLastUpdated(state, id)
      updateState(state, payload.payload)
    }
  },

  clearScriptsList(state) {
    state.projects = {}
    state.scripts = {}
    state.dryRunResponseForScript = ''
    state.status = 'idle'
    state.isLoading = false
    state.fetchError = ''
  },
  setDryRunResponseForScript(state, payload) {
    state.dryRunResponseForScript = payload.payload
  },
  setScriptsReducer(state, payload) {
    state.status = 'succeeded'
    state.scripts = {}
    state.projects = {}
    state.orderOfScriptsLastUpdated = []
    if (payload.payload) {
      payload.payload.forEach((scriptDetails) => {
        const projectId = scriptDetails.project_id
        if (!state.projects[projectId]) state.projects[projectId] = {}
        updateState(state, scriptDetails)
        const { identifier } = scriptDetails
        state.orderOfScriptsLastUpdated?.push(identifier)
        state.scripts[identifier] = scriptDetails // Immutable update
      })
    }
    state.isLoading = false
    state.fetchError = ''
  }
}

export function extraReducers(builder) {
  builder
    //   // save Projects
    .addCase(saveScripts.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(saveScripts.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      if (payload.payload) {
        const { id } = payload.payload
        state.scripts = { ...state.scripts, [id]: payload.payload }
        updateOrderOfLastUpdated(state, id)
        updateState(state, payload.payload)
      }
    })
    .addCase(saveScriptTemplate.rejected, (state) => {
      state.status = 'failed'
    })

    .addCase(saveScriptTemplate.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(saveScriptTemplate.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      if (payload.payload) {
        const { id } = payload.payload
        state.scripts = { ...state.scripts, [id]: payload.payload }
        updateState(state, payload.payload)
      }
    })
    .addCase(saveScripts.rejected, (state) => {
      state.status = 'failed'
    })
    //   fetch scripts

    .addCase(fetchScripts.pending, (state) => {
      state.scripts = {}
      state.status = 'loading'
      state.isLoading = true
    })
    .addCase(fetchScripts.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      const newEntities = {}
      const projectId = payload?.meta?.arg?.id

      if (payload.payload) {
        Object.entries(newEntities)?.forEach(([key, scriptData]) => {
          if (scriptData?.project_id === projectId) delete newEntities[key]
        })

        state.projects = { ...state?.projects, [projectId]: {} }

        payload.payload.forEach((scriptDetails) => {
          updateState(state, scriptDetails)
          const { id } = scriptDetails
          newEntities[id] = scriptDetails // Immutable update
        })
      }

      state.scripts = newEntities // Updating the state
      state.isLoading = false
      state.fetchError = ''
    })

    .addCase(fetchScripts.rejected, (state) => {
      state.status = 'failed'
      state.isLoading = false
      state.fetchError = `Oops! We couldn't fetch the data`
    })

    //   remove Function

    .addCase(removeScripts.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(removeScripts.fulfilled, (state) => {
      state.status = 'succeeded'
      state.scripts = {}
    })
    .addCase(removeScripts.rejected, (state) => {
      state.status = 'failed'
    })

    //   update Function

    .addCase(updateScript.pending, (state, payload) => {
      previousScripts = cloneDeep(state.scripts)
      const title = payload.meta.arg.dataToSend.title
      const scriptId = payload.meta.arg.scriptId
      state.scripts = { ...state.scripts, [scriptId]: { ...state.scripts[scriptId], title: title } }
      state.status = 'loading'
    })
    .addCase(updateScript.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      if (payload.payload) {
        const { id } = payload.payload
        const { json_script } = payload.payload
        if (!json_script || !id) return
        payload.payload.json_script = json_script
        state.scripts = { ...state.scripts, [id]: payload.payload }
      }
      previousScripts = {}
    })
    .addCase(updateScript.rejected, (state) => {
      state.scripts = { ...previousScripts }
      previousScripts = {}
      state.status = 'failed'
    })

    // change status
    .addCase(changeScriptStatus.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(changeScriptStatus.fulfilled, (state, payload) => {
      const { serviceId } = payload.urlData
      const scriptDetails = payload?.payload?.Data
      const draftedTriggerType = scriptDetails?.json_script?.trigger?.triggerType || 'hook'
      const publishedTriggerType = scriptDetails?.published_json_script?.trigger?.triggerType || 'hook'

      updateState(state, scriptDetails)

      let oldArrayName
      switch (payload.payload.OldStatus) {
        case '0':
          oldArrayName = 'delete'
          break
        case '2':
          oldArrayName = 'pause'
          break
        default:
          oldArrayName = 'active'
          break
      }
      if (!['webhook', 'cron', 'email', 'others'].includes(serviceId)) {
        updateStatus(
          state?.projects?.[scriptDetails?.project_id]?.appIntegration?.[serviceId]?.usedAsTrigger?.[oldArrayName],
          scriptDetails?.id
        )
        updateStatus(
          state?.projects?.[scriptDetails?.project_id]?.appIntegration?.[serviceId]?.usedAsAction?.[oldArrayName],
          scriptDetails?.id
        )
        if (draftedTriggerType !== publishedTriggerType) {
          updateStatus(
            state?.projects?.[scriptDetails?.project_id]?.appIntegration?.[serviceId]?.usedAsTrigger?.[oldArrayName],
            scriptDetails?.id
          )
          updateStatus(
            state?.projects?.[scriptDetails?.project_id]?.appIntegration?.[serviceId]?.usedAsAction?.[oldArrayName],
            scriptDetails?.id
          )
        }
      } else {
        updateStatus(state?.projects?.[scriptDetails?.project_id]?.[draftedTriggerType]?.[oldArrayName], scriptDetails?.id)
        if (draftedTriggerType !== publishedTriggerType) {
          updateStatus(state?.projects?.[scriptDetails?.project_id]?.[publishedTriggerType]?.[oldArrayName], scriptDetails?.id)
        }
      }

      state.scripts[scriptDetails?.id] = { ...scriptDetails }
      state.status = 'succeeded'
    })
    .addCase(changeScriptStatus.rejected, (state) => {
      state.status = 'failed'
    })

    // move flow
    .addCase(moveScript.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(moveScript.fulfilled, (state, payload) => {
      const status = payload?.payload?.data?.status === '2' ? 'pause' : payload?.payload?.data?.status === '0' ? 'delete' : 'active'

      const { data: scriptDetails, currentProjectid } = payload.payload

      updateState(state, scriptDetails)

      const draftedTriggerType = scriptDetails?.json_script?.trigger?.triggerType
      const publishedTriggerType = scriptDetails?.published_json_script?.trigger?.triggerType

      if (draftedTriggerType === 'polling' || draftedTriggerType === 'hook') {
        updateStatus(
          state?.projects?.[currentProjectid]?.appIntegration?.[scriptDetails?.json_script?.trigger?.serviceId]?.usedAsTrigger?.[status],
          scriptDetails?.id
        )
        updateStatus(
          state?.projects?.[currentProjectid]?.appIntegration?.[scriptDetails?.json_script?.trigger?.serviceId]?.usedAsAction?.[status],
          scriptDetails?.id
        )
        if (draftedTriggerType !== publishedTriggerType) {
          updateStatus(
            state?.projects?.[currentProjectid]?.appIntegration?.[scriptDetails?.published_json_script?.trigger?.serviceId]
              ?.usedAsTrigger?.[status],
            scriptDetails?.id
          )
          updateStatus(
            state?.projects?.[currentProjectid]?.appIntegration?.[scriptDetails?.published_json_script?.trigger?.serviceId]?.usedAsAction?.[
              status
            ],
            scriptDetails?.id
          )
        }
      } else {
        updateStatus(state?.projects?.[currentProjectid]?.[draftedTriggerType]?.[status], scriptDetails?.id)
        if (draftedTriggerType !== publishedTriggerType) {
          updateStatus(state?.projects?.[currentProjectid]?.[publishedTriggerType]?.[status], scriptDetails?.id)
        }
      }
      state.scripts[scriptDetails?.id] = { ...scriptDetails }
      state.status = 'succeeded'
    })
    .addCase(moveScript.rejected, (state) => {
      state.status = 'failed'
    })

    // duplicateflow
    .addCase(duplicateScript.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(duplicateScript.fulfilled, (state, payload) => {
      const { data: scriptDetails } = payload.payload

      updateState(state, scriptDetails)
      state.orderOfScriptsLastUpdated?.unshift(scriptDetails?.id)
      state.scripts[scriptDetails?.id] = { ...scriptDetails }
      state.status = 'succeeded'
    })
    .addCase(duplicateScript.rejected, (state) => {
      state.status = 'failed'
    })
    .addCase(fetchNameDescriptionByAi.pending, (state, payload) => {
      const { scriptId } = payload.meta.arg
      state.status = 'loading'
      if (state.scripts[scriptId]) {
        state.scripts[scriptId].ai = {
          ...state.scripts[scriptId].ai,
          namedescription: 'pending'
        }
      }
    })
    .addCase(fetchNameDescriptionByAi.fulfilled, (state, payload) => {
      const { scriptId } = payload.meta.arg
      if (state.scripts[scriptId]) {
        state.scripts[scriptId].ai = {
          ...state.scripts[scriptId].ai,
          namedescription: 'success'
        }
      }
      if (state.scripts[scriptId]) {
        const { scriptId } = payload.meta.arg
        const { title } = payload.payload.data
        const description = payload.payload.data.metadata.description
        if (!state.scripts[scriptId].title) {
          state.scripts[scriptId].title = title || 'Untitled Script'
        }
        if (!state.scripts[scriptId]?.metadata?.description) {
          state.scripts[scriptId].metadata = {
            ...state.scripts[scriptId].metadata,
            description: description || 'No description available'
          }
        }
      }
      state.status = 'succeeded'
    })
    .addCase(fetchNameDescriptionByAi.rejected, (state, payload) => {
      const { scriptId } = payload.meta.arg
      state.status = 'failed'
      if (state.scripts[scriptId]) {
        state.scripts[scriptId].ai = {
          ...state.scripts[scriptId].ai,
          namedescription: 'failed'
        }
      }
    })
}

function updateStatus(arr = [], scriptId = '') {
  const index = arr?.indexOf(scriptId)
  if (index !== -1) arr?.splice(index, 1)
}

function returnStatusObject(scriptDetails, obj) {
  let newObj = {
    active: [],
    pause: [],
    delete: [],
    ...obj
  }
  switch (scriptDetails.status) {
    case '0': // Delete
      if (newObj?.delete?.includes(scriptDetails.id)) break
      updateStatus(newObj?.active, scriptDetails.id)
      updateStatus(newObj?.pause, scriptDetails.id)
      newObj = { ...newObj, delete: [...newObj?.delete, scriptDetails.id] }
      break
    case '2': // Pause
      if (newObj?.pause?.includes(scriptDetails.id)) break
      updateStatus(newObj?.active, scriptDetails.id)
      updateStatus(newObj?.delete, scriptDetails.id)
      newObj = { ...newObj, pause: [...newObj?.pause, scriptDetails.id] }
      break
    default: // Active
      if (newObj?.active?.includes(scriptDetails.id)) break
      updateStatus(newObj?.pause, scriptDetails.id)
      updateStatus(newObj?.delete, scriptDetails.id)
      newObj = { ...newObj, active: [...newObj?.active, scriptDetails.id] }
      break
  }
  return newObj
}

const updateState = (state, scriptDetails) => {
  if (!scriptDetails.id) scriptDetails.id = scriptDetails.identifier
  const projectId = scriptDetails.project_id
  if (!state?.projects?.[projectId]) state.projects[projectId] = {}

  const updateProjectTriggerType = (triggerType) => {
    const triggerData = state?.projects?.[projectId]?.[triggerType] || {}
    state.projects[projectId][triggerType] = {
      ...triggerData,
      ...returnStatusObject(scriptDetails, triggerData)
    }
  }

  const triggerType = scriptDetails?.json_script?.trigger?.triggerType || 'others'
  if (['webhook', 'cron', 'email', 'others'].includes(triggerType)) {
    updateProjectTriggerType(triggerType)
  } else if (scriptDetails?.json_script?.trigger?.serviceId) {
    const { serviceId, serviceName, iconUrl } = scriptDetails?.json_script?.trigger
    state.projects[projectId].appIntegration = {
      ...state.projects[projectId]?.appIntegration,
      [serviceId]: {
        ...state.projects[projectId]?.appIntegration?.[serviceId],
        serviceName,
        iconUrl,
        usedAsTrigger: {
          ...state.projects[projectId]?.appIntegration?.[serviceId]?.usedAsTrigger,
          ...returnStatusObject(scriptDetails, state.projects[projectId]?.appIntegration?.[serviceId]?.usedAsTrigger || {})
        }
      }
    }
  }

  const updateMetadata = () => {
    const jsonScriptKey = 'json_script'
    Object.values(scriptDetails?.[jsonScriptKey]?.blocks)?.forEach((obj) => {
      if ([BlockTypes.API, BlockTypes.PLUG, BlockTypes.FUNCTION].includes(obj?.type)) {
        const serviceId = obj?.type === BlockTypes.PLUG ? obj?.service?.serviceId || obj?.metaData?.serviceId : obj?.type
        state.projects[projectId].appIntegration = {
          ...state.projects[projectId]?.appIntegration,
          [serviceId]: {
            ...state.projects[projectId]?.appIntegration?.[serviceId],
            usedAsAction: {
              ...state.projects[projectId]?.appIntegration?.[serviceId]?.usedAsAction,
              ...returnStatusObject(scriptDetails, state.projects[projectId]?.appIntegration?.[serviceId]?.usedAsAction || {})
            }
          }
        }
      }
    })
  }

  updateMetadata()

  // Remove service from appIntegration if both usedAsTrigger and usedAsAction have empty arrays
  Object.entries(state.projects[projectId].appIntegration || {}).forEach(([serviceId, service]) => {
    const { usedAsTrigger, usedAsAction } = service
    const isEmpty = (obj) => !obj?.active?.length && !obj?.delete?.length && !obj?.pause?.length

    if (isEmpty(usedAsTrigger) && isEmpty(usedAsAction)) {
      delete state.projects[projectId].appIntegration[serviceId]
    }
  })
}

function deleteValue(obj, valueToDelete) {
  for (const key in obj) {
    if (obj[key] instanceof Object) {
      deleteValue(obj[key], valueToDelete)

      for (const subKey in obj[key]) {
        if (Array.isArray(obj[key][subKey])) {
          obj[key][subKey] = obj[key][subKey].filter((item) => item !== valueToDelete)
        }
      }
    }
  }
}

function updateOrderOfLastUpdated(state, id) {
  const arrayOfOrder = state.orderOfScriptsLastUpdated || []
  const indexOfIdInOrder = arrayOfOrder?.indexOf(id)
  if (indexOfIdInOrder !== -1) arrayOfOrder?.splice(indexOfIdInOrder, 1)
  arrayOfOrder?.unshift(id)
  state.orderOfScriptsLastUpdated = arrayOfOrder
}
