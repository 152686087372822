import AppsRoundedIcon from '@mui/icons-material/AppsRounded'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Link, TextField, Tooltip, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import React, { useCallback, useRef, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getActionsByActionName } from '../../api/index'
import config from '../../config'
import { BlockTypes, ParamsEnums, sliderTypes, STEP_OPERATION_STATUS, Tabnames, THUNK_CALL_STATUS } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { DH_AUTH_TYPES } from '../../pages/developerHub/constants/developerHubConstants.ts'
import { SkeletonForListOfConnectedApps, SkeletonForShowGroupActionsAndPlug } from '../../pages/Metrics/SkeletonLoader.tsx'
import {
  getActionAndTrigger,
  useFetchPlugins,
  useInitialPluginsWithActions
} from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { openModalRedux, updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { addActionOrTriggerThunk, createDraftStepThunk } from '../../store/flowJson/flowJsonThunkV2.ts'
import { handleDeleteStepsV2 } from '../../store/invocationV2/invocationSliceV2.ts'
import { addInAppIntegration } from '../../store/scripts/scriptsSlice'
import { saveScripts } from '../../store/scripts/scriptsThunk'
import {
  addNewStepToStepData,
  deleteStepFromStepData,
  setAuthFieldsStart,
  setSelectedActionDetails,
  updateFlags
} from '../../store/stepsV2/stepSliceV3.ts'
import { closeSliderThunk } from '../../store/stepsV2/stepThunkV2'
import { builtInToolsArray, ownTriggers } from '../../utils/AddStepUtilities.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { convertStringToNumber, generateNewId, getNextUntitledName, replaceSpacesWithUnderscores } from '../../utils/utilities'
import ChatbotInitialOptions from '../chatbotInitialize/ChatbotInitialOptions.tsx'
import CloseSlidersButtonV2 from '../common/closeSlidersButtonV2.tsx'
import { errorToast } from '../customToast'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import './ActionsListComponent.scss'
import ActionsListComponent from './ActionsListComponent.tsx'
import AllAppsAddStep from './AllAppsAddStep.tsx'
import SelectedPlugToAddStep from './SelectedPlugToAddStep.tsx'

/**
 * Converts a string to a number.
 * @param {string} str - The string to convert.
 * @returns {number | undefined} - The converted number or undefined if conversion fails.
 */

export const getDelayPlugId = () => {
  const delayActionIdsByEnv = {
    local: ['rowraj5oh2tp'],
    testing: ['rowh499t1vsf'],
    default: ['row6hurrxf00']
  }
  return delayActionIdsByEnv[process.env.REACT_APP_API_ENVIRONMENT] || delayActionIdsByEnv.default
}

interface AddStepSliderProps {
  position: string | number
  parent: string
  setSearchParams: (params: Record<string, string>) => void
  scriptId: string
  orgId: string
  advanceConfiguration: boolean
  createFlow?: boolean
}
/**
 * AddStepSlider component.
 * @param {AddStepSliderProps} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
function AddStepSlider({
  position,
  parent = 'root',
  setSearchParams,
  scriptId,
  orgId,
  projectId,
  isEmbedUrl,
  type = 'action',
  placeholder = 'Search Apps and tools',
  title = 'Add a Step',
  advanceConfiguration = false,
  createFlow = false,
  finalProjectId
}: AddStepSliderProps) {
  let builtInTools = builtInToolsArray
  if (advanceConfiguration)
    builtInTools = builtInTools.filter(
      (tool) => !['ifBlock', 'comment', 'variable', 'ifGroup', 'ai-step', 'delay-step', 'human-intervention', 'memory'].includes(tool.rowid)
    )

  position = convertStringToNumber(position)
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingSearched, setLoadingSearched] = useState(false)
  const [searchedResults, setSearchedResults] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pageState, setPageState] = useState({ insideAllApps: false })
  const { flowJsonBlocks, onlyActionOfMyServiceAppear, integrationServiceId, triggerServiceIdToSkip } = useCustomSelector((state) => {
    const currentIntegrationSettings = state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings || {}
    const flowJson = state?.flowJsonV2?.[scriptId]?.flowJson
    const integrationServiceId = currentIntegrationSettings?.serviceId
    const triggerServiceId = flowJson?.trigger?.serviceId
    const triggerServiceIdToSkip = integrationServiceId && isEmbedUrl ? flowJson?.trigger?.serviceId : null
    return {
      flowJsonBlocks: flowJson?.blocks,
      onlyActionOfMyServiceAppear:
        isEmbedUrl &&
        !flowJson?.order?.root?.length &&
        integrationServiceId &&
        integrationServiceId !== 'webhook' &&
        integrationServiceId !== triggerServiceId,
      integrationServiceId,
      triggerServiceIdToSkip
    }
  })
  const { data: integrationServiceJson } = useFetchPlugins(orgId, [], [integrationServiceId])

  const integrationService = integrationServiceJson?.[integrationServiceId]
  const { data: delayWalaAction } = getActionAndTrigger({ rowid: getDelayPlugId() })
  const { data: initialPlugins, isLoading: initialPlugsLoading } = useInitialPluginsWithActions(orgId)
  const searchTextFieldRef = useRef()

  const debouncedSearch = useCallback(
    debounce(async (textvalue) => {
      const searchquerytemp = searchTextFieldRef.current?.querySelector('input').value
      if (searchquerytemp === textvalue) {
        // if (permittedEvents?.length) return
        setLoadingSearched(true)
        const data = (await getActionsByActionName(searchquerytemp, [], type)) || []
        const currentText = searchTextFieldRef.current?.querySelector('input').value
        if (searchquerytemp === currentText) {
          setSearchedResults(data)
          setLoadingSearched(false)
        }
      }
    }, 300),
    []
  )

  /**
   * Sets search parameters and dispatches actions to update state.
   * @param {string} finalId - The final ID of the step.
   * @param {object} dataToUpdate - The data to update.
   * @param {string} [name=''] - The name of the step.
   * @param {string} [AImessage=''] - The AI message.
   */
  const setSearchAndDispatchParams = (finalId, dataToUpdate, name = '', AImessage = '') => {
    const paramsToSet = { stepId: finalId, slugName: name || finalId }
    if (AImessage) paramsToSet['AImessage'] = AImessage
    setSearchParams(paramsToSet)
    batch(() => {
      dispatch(updateAppInfoState({ currentStepType: dataToUpdate.type }))
      dispatch(addNewStepToStepData({ dataToUpdate, stepId: finalId }))
    })
  }

  const handleAddActionByAI = (action) => {
    const preferedauthversion = action.preferedauthversion
    const domain = action.plugindomain
    const clientGeneratedId = `func${generateNewId()}`
    const pluginIcon = action?.iconurl || ''
    const pluginDataFromAction = {
      contributeAction: true,
      preferedauthversion,
      serviceId: action?.pluginrecordid,
      pluginDomain: domain,
      iconUrl: pluginIcon,
      type: 'action',
      inputFields: [],
      inputJson: {},
      isVisible: false,
      selectedValues: {
        authData: {},
        inputData: {}
      }
    }
    dispatch(
      setSelectedActionDetails({
        pluginDataFromAction,
        stepId: clientGeneratedId,
        tabName: 'draft'
      })
    )
    setSearchParams({ stepId: clientGeneratedId, slugName: 'Action_By_AI' })
    dispatch(updateAppInfoState({ currentStepType: sliderTypes.CONTRIBUTE_ACTION, showFullScreen: true }))
    dispatch(
      setAuthFieldsStart({
        pluginDataFromAction,
        pluginDomain: domain,
        filterField: 'pluginrecordid',
        plugId: action?.pluginrecordid,
        tableName: 'auth',
        secondFilterField: 'rowid',
        setSearchParams: setSearchParams,
        pluginIcon: pluginIcon,
        orderGroup: 'root',
        stepId: clientGeneratedId,
        contributeAction: true
      })
    )
  }

  const addPluginInAdvanceConfiguration = async (actionDetails) => {
    const {
      perform,
      performlist,
      performsubscribe,
      performunsubscribe,
      modifytriggerdata,
      transferoption,
      triggertype,
      inputjson,
      rowid,
      name,
      iconurl,
      actionversionrecordid,
      pluginrecordid,
      pluginname,
      authtype
    } = actionDetails
    const pluginDataFromAction = {
      source: perform,
      performList: performlist,
      performSubscribe: performsubscribe,
      performUnsubscribe: performunsubscribe,
      modifyTriggerData: modifytriggerdata,
      transferOption: transferoption,
      triggerType: triggertype,
      selectedCreate: rowid,
      actionName: replaceSpacesWithUnderscores(name),
      actionVersionId: actionversionrecordid,
      serviceId: pluginrecordid,
      serviceName: pluginname,
      inputFields: inputjson?.inputFields,
      inputJson: { steps: inputjson?.steps, blocks: inputjson?.blocks },
      authIdLookup: actionDetails?.authidlookup,
      authType: authtype,
      iconUrl: iconurl,
      selectedValues: { authData: {}, inputData: {} }
    }
    if (authtype === DH_AUTH_TYPES.noAuth) {
      pluginDataFromAction.selectedValues.authData = { id: DH_AUTH_TYPES.noAuth, type: DH_AUTH_TYPES.noAuth }
    }
    const flagsToSetFalse = Object.keys(inputjson?.blocks).filter((key) => !!inputjson?.blocks?.[key]?.source)
    batch(() => {
      dispatch(updateFlags({ ...flagsToSetFalse }))
      dispatch(updateAppInfoState({ currentStepType: 'plugin' }))
      // dispatch(setAuthData({}))
      dispatch(
        setSelectedActionDetails({
          pluginDataFromAction,
          stepId: 'CRON_PRE_PROCESS',
          iconUrl: iconurl
        })
      )
      setSearchParams({ stepId: 'CRON_PRE_PROCESS', slugName: 'CRON_PRE_PROCESS' })
    })
    // set steps / order / jsonScript
  }
  /**
   * Handles adding a plugin action.
   * @param {any} action - The action to add.
   * @param {boolean} [isDelay=false] - Whether the action is a delay.
   */
  const handleAddPluginAction = (action, isDelay = false, isCustomStep = false) => {
    if (advanceConfiguration) {
      dispatch(handleDeleteStepsV2({ payload: { key: 'CRON_PRE_PROCESS' }, type: BlockTypes.FUNCTION }))
      addPluginInAdvanceConfiguration(action)
      return
    }
    if (isCustomStep) {
      handleAddActionByAI(action)
      return
    }
    dispatch(
      addActionOrTriggerThunk({
        actionDetails: action,
        clientGeneratedId: `func${generateNewId()}`,
        setSearchParams,
        parent: parent || 'root',
        position,
        isDelay
      })
    )
  }

  /**
   * Handles changes in the search input.
   * @param {string} textValue - The new value of the search input.
   */
  function handleOnChange(textValue) {
    setPageState({ insideAllApps: false })
    setSearchTerm(textValue)
    debouncedSearch(textValue)
  }
  const handleAddBuiltInTool = async (content) => {
    if (advanceConfiguration) {
      dispatch(deleteStepFromStepData({ stepId: 'CRON_PRE_PROCESS' }))
      dispatch(handleDeleteStepsV2({ payload: { key: 'CRON_PRE_PROCESS' }, type: BlockTypes.FUNCTION }))
      setSearchAndDispatchParams(
        'CRON_PRE_PROCESS',
        {
          type: content.rowid,
          title: 'CRON_PRE_PROCESS',
          id: 'CRON_PRE_PROCESS'
        },
        'CRON_PRE_PROCESS'
      )
      return
    }
    if (['function', 'ai-step'].includes(content.rowid)) {
      handleFunctionButtonClick(content.rowid === 'ai-step')
      return
    }

    if (content.rowid === 'memory') {
      handleOnChange(content.name)
      return
    }

    const aistep = content.rowid === 'ai-step' ? true : undefined

    const id = generateNewId(8)
    let blockToAdd = {}
    let ifBlockToAdd = {}
    let dataToUpdate = {}
    let finalId
    let ifBlockId
    let ifBlockSlugName
    let finalSlugName
    const value = content.rowid === 'ai-step' ? 'function' : content.rowid
    if (value === 'human-intervention') {
      dispatch(updateAppInfoState({ currentStepType: 'human-intervention' }))
      setSearchParams({ stepId: 'human-intervention', slugName: 'human-intervention', parent, position })
      return
    }
    if (value === 'delay-step') {
      handleAddPluginAction(delayWalaAction?.[0], true)
      return
    }
    if (value === BlockTypes.FUNCTION || value === BlockTypes.API) {
      finalId = `func${id}`
      finalSlugName = getNextUntitledName(flowJsonBlocks)
      blockToAdd = {
        [finalSlugName]: {
          type: value,
          status: STEP_OPERATION_STATUS.DRAFTED,
          identifier: finalId,
          iconUrl: '',
          aistep
        }
      }
      dataToUpdate = {
        type: value,
        title: finalSlugName,
        id: finalId
      }
      setSearchAndDispatchParams(finalId, dataToUpdate, finalSlugName, content?.AImessage)
    } else if (value === BlockTypes.VARIABLE) {
      finalSlugName = getNextUntitledName(flowJsonBlocks)
      finalId = `vari${id}`
      blockToAdd = {
        [finalSlugName]: {
          type: value,
          data: '',
          html: '',
          status: STEP_OPERATION_STATUS.DRAFTED,
          identifier: finalId
        }
      }
      dataToUpdate = {
        type: value,
        title: finalSlugName
      }
      setSearchAndDispatchParams(finalId, dataToUpdate, finalSlugName)
    } else if (value === BlockTypes.IFBLOCK) {
      finalSlugName = getNextUntitledName(flowJsonBlocks, 'ifBlock')
      finalId = `ifcb${id}`
      blockToAdd = {
        [finalSlugName]: {
          type: value,
          condition: '',
          html: '',
          statement: '',
          status: STEP_OPERATION_STATUS.DRAFTED,
          identifier: finalId
        }
      }
      dataToUpdate = { type: value, title: finalSlugName }

      setSearchAndDispatchParams(finalId, dataToUpdate, finalSlugName)
    } else if (value === BlockTypes.IFGROUP) {
      finalId = `swtc${id}`
      finalSlugName = finalId
      blockToAdd = {
        [finalId]: {
          type: value,
          identifier: finalId
        }
      }
      ifBlockId = `ifcb${id}`
      ifBlockSlugName = ifBlockId
      ifBlockToAdd = {
        [ifBlockSlugName]: {
          type: BlockTypes.IFBLOCK,
          condition: '',
          html: '',
          statement: '',
          status: STEP_OPERATION_STATUS.DRAFTED,
          identifier: ifBlockId,
          parent: finalId
        }
      }

      dataToUpdate = { type: BlockTypes.IFBLOCK, title: ifBlockSlugName }
      setSearchAndDispatchParams(ifBlockId, dataToUpdate, ifBlockSlugName)
    } else {
      if (value !== BlockTypes.COMMENT && (value?.length > 100 || value?.length === 0)) {
        errorToast('Character length cannot be more than 100 or be 0')
        return
      }
      finalId = `cmnt${id}`
      finalSlugName = finalId
      blockToAdd = {
        [finalId]: {
          type: BlockTypes.COMMENT,
          data: value === BlockTypes.COMMENT ? '' : value,
          identifier: finalId
        }
      }
    }
    dispatch(
      createDraftStepThunk({
        block: blockToAdd,
        identifier: finalId,
        orderGroup: parent || 'root',
        setSearchParams,
        position: position,
        slugName: finalSlugName,
        ...(value === BlockTypes.IFGROUP
          ? {
              ifBlockData: {
                ifBlockToAdd,
                ifBlockId,
                ifBlockSlugName
              }
            }
          : {}),
        aistep
      })
    )
    if (value === BlockTypes.COMMENT) dispatch(closeSliderThunk({ setSearchParams }))
  }

  const handleSelectAPlug = (plug) => {
    setPageState({ plugSelected: true, plug })
  }
  const addService = (service) => {
    dispatch(
      addInAppIntegration({
        serviceId: service.pluginrowid,
        serviceName: service.name,
        iconUrl: service.iconurl,
        projectId: finalProjectId || `proj${orgId}`
      })
    )
    if (finalProjectId) {
      dispatch(updateAppInfoState({ modalType: false, isModalOpen: false }))
      navigate(`/projects/${orgId}${finalProjectId ? `/${finalProjectId}` : ''}/appsexplore/servicepage/${service.pluginrowid}`)
    }
  }
  const handleSelectConnnectedPlug = (plug) => {
    if (createFlow) {
      addService(plug)
      return
    }
    setPageState({ connectedPlugSelected: true, plug })
  }

  const createflowWithTrigger = async (triggerEvent) => {
    const currentProjectId = projectId || `proj${orgId}`
    const triggerEventRowId = triggerEvent?.rowid
    let triggerDetails
    if (triggerEventRowId) {
      triggerDetails = {
        eventId: triggerEventRowId
      }
    }

    dispatch(updateAppInfoState({ modalType: false, isModalOpen: false }))
    dispatch(
      saveScripts({
        project_id: currentProjectId,
        org_id: orgId,
        triggerDetails
      })
    ).then((e) => {
      if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED) {
        navigate(
          `${config.projectsBaseUrl}/${orgId}/${finalProjectId}${config.workflowBaseUrl}/${e.payload.id}/${Tabnames.DRAFT}?stepId=SET_TRIGGER&slugName=trigger`
        )
      }
    })
  }

  function handleAddTriggerAction(trigger) {
    if (createFlow) {
      createflowWithTrigger(trigger)
      return
    }
    dispatch(updateAppInfoState({ currentStepType: BlockTypes.TRIGGER }))
    setSearchParams({ stepId: 'SET_TRIGGER', slugName: BlockTypes.TRIGGER })
    dispatch(addActionOrTriggerThunk({ actionDetails: trigger, clientGeneratedId: generateNewId(), setSearchParams }))
  }

  const createFlowFunction = (eventId: string | false = false, triggerDetails = undefined): void => {
    if (eventId) triggerDetails = { triggerType: eventId, type: 'add' }
    dispatch(updateAppInfoState({ modalType: eventId }))
    dispatch(
      saveScripts({
        project_id: finalProjectId,
        org_id: orgId,
        triggerDetails
      })
    ).then((e) => {
      if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED) {
        navigate(
          `${config.projectsBaseUrl}/${orgId}/${finalProjectId}${config.workflowBaseUrl}/${e.payload.id}/${Tabnames.DRAFT}${
            eventId ? '' : '?stepId=SET_TRIGGER&slugName=selecttrigger'
          }`
        )
      }
    })
  }

  const navigateToDedicatePage = (event: string): void => {
    dispatch(updateAppInfoState({ openModal: true, modalType: event }))
    createFlowFunction(event)
  }

  function addOwnTrigger(trigger) {
    if (createFlow) {
      navigateToDedicatePage(trigger)
    } else {
      dispatch(updateAppInfoState({ currentStepType: trigger }))
      setSearchParams({ stepId: 'SET_TRIGGER', slugName: trigger })
    }
  }

  const searchBar = (
    <Box
      className='bg-white !z-[99] py-3 px-3 mx-2 flex justify-start items-center gap-3'
      sx={{
        border: '1px solid #000'
      }}
    >
      {pageState?.insideAllApps || pageState?.connectedPlugSelected || pageState?.plugSelected || searchTerm ? (
        <ArrowBackIosNewIcon
          color='gray'
          onClick={() => {
            setPageState({ insideAllApps: Boolean(pageState?.plugSelected) })
            setSearchTerm('')
          }}
          className='cursor-pointer'
          fontSize='medium'
        />
      ) : (
        <SearchIcon color='gray' fontSize='medium' />
      )}
      <TextField
        ref={searchTextFieldRef}
        id='add-step-input'
        fullWidth
        noborder='true'
        value={searchTerm}
        onChange={(e) => handleOnChange(e.target.value)}
        placeholder={placeholder}
        steptitle='true'
      />
    </Box>
  )

  function handleFunctionButtonClick(isAistep = false) {
    setPageState({ createNewFunction: true, aistep: isAistep })
  }
  const builtInToolsFilterWithParent = builtInTools?.filter((tool) => parent === 'root' || tool.rowid !== 'ifGroup')
  const filteredBuiltInTools = !searchTerm
    ? builtInToolsFilterWithParent
    : builtInToolsFilterWithParent?.filter((tool) => !searchTerm || tool.name?.toLowerCase().includes(searchTerm?.toLowerCase()?.trim()))

  const filteredOwnTriggers = !searchTerm
    ? ownTriggers
    : ownTriggers?.filter((tool) => !searchTerm || tool.name?.toLowerCase().includes(searchTerm?.toLowerCase()?.trim()))
  const buildInToolsRenderer = filteredBuiltInTools?.length ? (
    <Box className='flex flex-col w-full gap-1'>
      <Typography variant='smallHeading' className='p-2 mx-1'>
        Built-In Tools
      </Typography>
      <Box className='flex-col'>
        {filteredBuiltInTools?.map((tool, index) => {
          return (
            <Box
              className='px-4 py-3 cursor-pointer hoverable-block w-full gap-2 flex justify-between items-center'
              sx={{
                borderBottom: filteredBuiltInTools?.length - 1 !== index ? '0.2px solid #ccc' : 'unset',
                '&:hover .read-more-link': {
                  display: 'inline-block'
                }
              }}
              key={tool.name}
              onClick={() => {
                handleAddBuiltInTool(tool)
              }}
            >
              <Box className='flex justify-start items-center gap-4'>
                <IconWrapper component={tool?.component} size='32px' />
                <Box className='flex flex-col'>
                  <Typography>{tool?.name}</Typography>
                  <Typography behavior='colored' color='gray' className='one-line-trim'>
                    {tool?.description?.length > 70 ? `${tool?.description?.slice(0, 70)}...` : tool?.description}

                    {tool?.link && (
                      <Link
                        href={tool.link}
                        target='_blank'
                        onClick={(e) => e.stopPropagation()}
                        className='read-more-link'
                        sx={{
                          display: 'none',
                          marginLeft: '4px'
                        }}
                      >
                        Read more
                      </Link>
                    )}
                  </Typography>
                </Box>
              </Box>
              {['function', 'memory', 'ai-step']?.includes(tool.rowid) && <ArrowForwardIosIcon className='opacity-0' fontSize='small' />}
            </Box>
          )
        })}
      </Box>
    </Box>
  ) : null
  const ownTriggersRenderer = filteredOwnTriggers?.length ? (
    <Box className='flex flex-col w-full'>
      {/* <Typography variant='smallHeading' className='p-3'>
        Built-In Triggers
      </Typography> */}
      {filteredOwnTriggers?.map((tool, index) => {
        return (
          <Box
            className='px-3 py-2 cursor-pointer hoverable-block w-full gap-2 flex justify-between items-center'
            sx={{
              borderBottom: filteredOwnTriggers?.length - 1 !== index ? '0.2px solid #ccc' : 'unset',
              '&:hover .read-more-link': {
                display: 'inline-block'
              }
            }}
            key={tool.name}
            onClick={() => {
              addOwnTrigger(tool.rowid)
            }}
          >
            <Box className='flex justify-start items-center gap-3'>
              <IconWrapper component={tool?.component} size='28px' />
              <Box className='flex flex-col'>
                <Typography variant='h7'>{tool?.name}</Typography>
                <Typography behavior='colored' color='gray' className='one-line-trim'>
                  {tool?.description?.length > 50 ? `${tool?.description?.slice(0, 50)}...` : tool?.description}

                  {tool?.link && (
                    <Link
                      href={tool.link}
                      target='_blank'
                      onClick={(e) => e.stopPropagation()}
                      className='read-more-link'
                      sx={{
                        display: 'none',
                        marginLeft: '4px'
                      }}
                    >
                      Read more
                    </Link>
                  )}
                </Typography>
              </Box>
            </Box>
            {['function', 'memory', 'ai-step']?.includes(tool.rowid) && <ArrowForwardIosIcon className='opacity-0' fontSize='small' />}
          </Box>
        )
      })}
    </Box>
  ) : null
  const renderSearchedResults = loadingSearched ? (
    SkeletonForShowGroupActionsAndPlug
  ) : (
    <Box className='flex flex-col gap-4 w-full'>
      {searchedResults?.length ? (
        searchedResults?.map((plug) => {
          if (plug.pluginrowid === triggerServiceIdToSkip) return null
          return (
            <ActionsListComponent
              key={plug.pluginrowid}
              actionsArray={type === 'action' ? plug?.actions : plug?.triggers}
              addActionFunction={type === 'action' ? handleAddPluginAction : handleAddTriggerAction}
              serviceIconUrl={plug?.iconurl}
              serviceName={plug?.pluginname}
              isPluginVerified={plug?.pluginverified}
            />
          )
        })
      ) : (
        <Box className='flex flex-col justify-center items-center gap-2 p-2'>
          <Typography variant='h7'>No Apps found for &apos;{searchTerm}&apos;</Typography>
          <Link
            component='button'
            onClick={() => {
              dispatch(openModalRedux({ modalType: 'requestPlug' }))
            }}
          >
            Request An Integration
          </Link>
        </Box>
      )}
    </Box>
  )

  const filteredInitialPlugins =
    initialPlugins?.filter(
      (plug) =>
        (!searchTerm || `${plug.pluginname}`?.toLowerCase().includes(searchTerm?.toLowerCase()?.trim())) &&
        plug?.pluginrowid !== triggerServiceIdToSkip
    ) || []

  const connectedAppsRenderer = (
    // filteredInitialPlugins?.length || initialPlugsLoading ? (
    <Box className='flex-col gap-3 p-2 mx-1 my-1'>
      <Typography variant='smallHeading'>Apps</Typography>
      <Box className='flex flex-wrap gap-3 w-full items-center'>
        {initialPlugsLoading ? SkeletonForListOfConnectedApps : null}
        {(filteredInitialPlugins || [])?.slice(0, 14).map((service) => {
          if (type === 'trigger' && !service.triggers?.length) return null
          if (!service?.actions?.length) return null
          const serviceId = service.pluginrowid

          return (
            <Tooltip key={serviceId} title={service.pluginname} arrow>
              <Box
                onClick={() => handleSelectConnnectedPlug(service)}
                className='cursor-pointer hoverable-block flex justify-center items-center bg-white border border-gray-300 w-12 h-12'
              >
                <IconWrapper iconUrl={service.iconurl} size='28px' />
              </Box>
            </Tooltip>
          )
        })}

        {searchTerm ? null : (
          <Tooltip title='All Apps' arrow>
            <Box
              onClick={() => setPageState({ insideAllApps: true })}
              className='cursor-pointer hoverable-block flex gap-2 px-2 justify-center items-center bg-white border border-gray-300 h-12'
            >
              <IconWrapper component={<AppsRoundedIcon className='!w-full !h-full' />} size='28px' />
              <Typography>All Apps</Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
  // ) : null

  return (
    <Box className='flex flex-col h-full w-full'>
      {createFlow ? null : <CloseSlidersButtonV2 isBackButton />}

      <Box className={`flex  justify-between items-center w-full ${createFlow ? '' : 'p-3'}`}>
        <Box className='flex justify-start items-center gap-3'>
          {pageState?.createNewFunction ? (
            <ArrowBackIosNewIcon
              color='gray'
              onClick={() => {
                setPageState({ insideAllApps: false })
              }}
              className='cursor-pointer'
              fontSize='medium'
            />
          ) : null}
          <Typography variant='h5' fontWeight={500}>
            {pageState?.aistep ? 'AI Step' : pageState?.createNewFunction ? 'Custom Logic (JS Code)' : title}
          </Typography>
        </Box>
        {createFlow ? null : <CloseSlidersButtonV2 type='icon' />}
      </Box>
      {onlyActionOfMyServiceAppear ? (
        <SelectedPlugToAddStep plug={integrationService} addActionFunction={handleAddPluginAction} />
      ) : pageState?.createNewFunction ? (
        <ChatbotInitialOptions aistep={pageState?.aistep} />
      ) : (
        <Box className='flex flex-col h-full'>
          {searchBar}
          {pageState.plugSelected ? (
            <SelectedPlugToAddStep
              plug={pageState.plug}
              addActionFunction={type === 'action' ? handleAddPluginAction : handleAddTriggerAction}
              type={type}
            />
          ) : pageState.insideAllApps ? (
            <AllAppsAddStep onClickOnElement={handleSelectAPlug} triggerServiceIdToSkip={triggerServiceIdToSkip} />
          ) : pageState.connectedPlugSelected ? (
            <ActionsListComponent
              actionsArray={type === 'action' ? pageState?.plug?.actions : pageState?.plug?.triggers}
              addActionFunction={type === 'action' ? handleAddPluginAction : handleAddTriggerAction}
              serviceIconUrl={pageState?.plug?.iconurl}
              serviceName={pageState?.plug?.pluginname}
              isPluginVerified={pageState?.plug?.pluginverified}
            />
          ) : (
            <Box className='flex flex-col my-2 gap-2'>
              {loadingSearched || !searchTerm ? connectedAppsRenderer : null}
              {type === 'action' ? buildInToolsRenderer : ownTriggersRenderer}
            </Box>
          )}
          {searchTerm ? renderSearchedResults : null}
        </Box>
      )}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(AddStepSlider), [
    ParamsEnums.scriptId,
    ParamsEnums.orgId,
    'position',
    'parent',
    ParamsEnums.projectId,
    ParamsEnums.isEmbedUrl
  ])
)
