import { SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import cloneDeep from 'lodash.clonedeep'
import { InvocationDataType, InvocationReduxDataType } from '../../types/Invocation.ts'
import { $InvocationReduxTypeStructure } from '../../types/reduxCore.ts'
import { BlockTypes } from '../../enums'
import { updateGroupedSnippet, updateSnippet } from '../../utils/utilities'
import { actionType } from '../../types/utility.ts'
import { INPUT_FIELDS_SUGGESTIONS } from '../../pages/developerHub/constants/developerHubConstants.ts'
import { parseJSONProperties } from './invocationThunkV2.ts'

export const initialState: $InvocationReduxTypeStructure = {}
export const initialStateObj: InvocationReduxDataType = {
  invocationData: {
    _id: '',
    projectId: '',
    orgId: '',
    ip: '',
    scriptId: '',
    version: '',
    requestTimestamp: '',
    status: '',
    errorMessage: '',
    console: '',
    output: '',
    requestSnapshot: '',
    responseSnapshot: '',
    requestSnapshotJsonData: {},
    responseSnapshotJsonData: {},
    vals: {},
    stepRequestSnapshot: '',
    authData: {},
    stepOrder: [],
    inputData: {},
    __v: 0,
    stepOrderJson: {}
  },
  invocationCount: 0,
  whenResponseSnapshotJsonData: {},
  groupedSuggestions: {},
  context: {
    context: {
      req: {
        query: {},
        body: {},
        headers: {}
      },
      res: {},
      vals: {},
      authData: {}
    }
  },
  usedVariables: {
    functions: {},
    variables: {},
    payloadData: {}
  },
  isLoading: false,
  showUsedVariables: true
}

// Helper function to initialize section state if it doesn't exist
const initializeSectionState = (state, sectionIdOrScriptId) => {
  if (!state?.[sectionIdOrScriptId]) {
    state[sectionIdOrScriptId] = { ...initialStateObj }
  }
}
// Helper function to update grouped suggestions and context
const updateSuggestionsAndContext = (state, sectionIdOrScriptId, data) => {
  const { suggestionJson, context } = updateGroupedSnippet(data, state[sectionIdOrScriptId]?.usedVariables)
  if (!state[sectionIdOrScriptId]) state[sectionIdOrScriptId] = {}
  state[sectionIdOrScriptId].groupedSuggestions = suggestionJson
  state[sectionIdOrScriptId].context = context
}

export const reducers: ValidateSliceCaseReducers<$InvocationReduxTypeStructure, SliceCaseReducers<$InvocationReduxTypeStructure>> = {
  addInvocationInitialState(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)
  },
  // set whenResponseSnapshotJsonData
  setWhenResponseSnapshotJsonDataInInvocationJson(state: $InvocationReduxTypeStructure, action) {
    const { sectionIdOrScriptId } = action?.urlData
    const parentFlowHitId = action.payload.parentFlowHitId
    if (state?.[sectionIdOrScriptId] && parentFlowHitId) {
      if (!state?.[sectionIdOrScriptId]?.whenResponseSnapshotJsonData?.[parentFlowHitId]) {
        state[sectionIdOrScriptId].whenResponseSnapshotJsonData[parentFlowHitId] = {}
      }
      state[sectionIdOrScriptId].whenResponseSnapshotJsonData[parentFlowHitId] = action.payload.payload
    }
  },
  setShowUsedVariables(state, action: any) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)
    state[sectionIdOrScriptId].showUsedVariables = action?.payload
  },
  setInvocationDataV2(state, action: actionType<InvocationDataType>) {
    const { sectionIdOrScriptId } = action?.urlData
    const customId = 'selectedLog'
    initializeSectionState(state, sectionIdOrScriptId)
    initializeSectionState(state, customId)

    const dataToSpread = cloneDeep(action.payload)
    parseJSONProperties(dataToSpread)

    state[sectionIdOrScriptId].invocationData = { ...dataToSpread }
    state[customId].invocationData = { ...dataToSpread }
    updateSuggestionsAndContext(state, sectionIdOrScriptId, dataToSpread)
    updateSuggestionsAndContext(state, customId, dataToSpread)
  },
  removeInvocationDataV2(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    const customId = 'selectedLog'
    initializeSectionState(state, sectionIdOrScriptId)
    initializeSectionState(state, customId)

    const initialStateCopy = { ...initialStateObj, usedVariables: state?.[sectionIdOrScriptId]?.usedVariables }
    updateSuggestionsAndContext(state, sectionIdOrScriptId, initialStateCopy)
    updateSuggestionsAndContext(state, customId, initialStateCopy)

    state[sectionIdOrScriptId] = initialStateCopy
    state[customId] = initialStateCopy
  },
  removeInvocationForDH(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    state[sectionIdOrScriptId] = { ...initialStateObj }
  },
  handleAddStepsV2(state, action: actionType<{ type: string; payload: { key: string; value: any } }>) {
    const { sectionIdOrScriptId } = action?.urlData
    const { type, payload } = action.payload
    const { key, requestConfig, console } = payload
    let value = payload?.value
    if (value === undefined) value = null
    initializeSectionState(state, sectionIdOrScriptId)

    const updateInvocationData = (vals) => {
      const updatedVals = { ...state[sectionIdOrScriptId].invocationData.vals, ...vals }
      state[sectionIdOrScriptId].invocationData = { ...state[sectionIdOrScriptId].invocationData, vals: updatedVals }
      updateSuggestionsAndContext(state, sectionIdOrScriptId, { ...state[sectionIdOrScriptId].invocationData, vals: updatedVals })
    }
    const updateInvocationDataForResponse = (response) => {
      state[sectionIdOrScriptId].invocationData = { ...state[sectionIdOrScriptId].invocationData, output: response }
    }

    switch (type) {
      case BlockTypes.RESPONSE:
        updateInvocationDataForResponse(value)
        break
      case BlockTypes.VARIABLE:
        updateInvocationData({ [key]: value })
        break
      case BlockTypes.API:
      case BlockTypes.FUNCTION:
      case BlockTypes.PLUG: {
        const updatedInvocation = { ...state?.[sectionIdOrScriptId]?.invocationData?.responseSnapshot, [key]: value }
        const updatedStepRequestSnapshot = cloneDeep(state[sectionIdOrScriptId]?.invocationData?.stepRequestSnapshot) || {}
        updatedStepRequestSnapshot[key] = requestConfig

        state[sectionIdOrScriptId].invocationData = {
          ...state[sectionIdOrScriptId].invocationData,
          responseSnapshot: updatedInvocation,
          stepRequestSnapshot: updatedStepRequestSnapshot,
          console: { ...state[sectionIdOrScriptId].invocationData.console, [key]: console }
        }
        updateSuggestionsAndContext(state, sectionIdOrScriptId, {
          ...state[sectionIdOrScriptId].invocationData,
          responseSnapshot: updatedInvocation
        })
        break
      }
      case BlockTypes.IFBLOCK:
        state[sectionIdOrScriptId].invocationData.responseSnapshot = {
          ...state[sectionIdOrScriptId].invocationData.responseSnapshot,
          [key]: value
        }
        break
      default:
        break
    }
  },
  handleDeleteStepsV2(state, action: actionType<{ type: string; payload: { key: string } }>) {
    const { sectionIdOrScriptId } = action?.urlData
    const { type, payload } = action.payload
    const { key } = payload

    initializeSectionState(state, sectionIdOrScriptId)

    const updateInvocationData = (keyToDelete, dataKey) => {
      const updatedData = { ...state[sectionIdOrScriptId].invocationData[dataKey] }
      delete updatedData[keyToDelete]

      const updatedInvocationData = {
        ...state[sectionIdOrScriptId].invocationData,
        [dataKey]: updatedData
      }
      delete updatedInvocationData?.console?.[keyToDelete]
      updateSuggestionsAndContext(state, sectionIdOrScriptId, updatedInvocationData)
      state[sectionIdOrScriptId].invocationData = updatedInvocationData
    }

    if (type === BlockTypes.VARIABLE) {
      updateInvocationData(key, 'vals')
    } else if ([BlockTypes.FUNCTION, BlockTypes.API, BlockTypes.PLUG, BlockTypes.IFBLOCK].includes(type)) {
      updateInvocationData(key, 'responseSnapshot')
    }
  },
  removeDhInvocation(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)

    state[sectionIdOrScriptId].invocationData = initialStateObj.invocationData
    updateSuggestionsAndContext(state, sectionIdOrScriptId, initialStateObj)
  },
  setInvocationAuthData(state, action: any) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)

    state[sectionIdOrScriptId].invocationData = {
      ...state[sectionIdOrScriptId]?.invocationData,
      authData: {
        ...state[sectionIdOrScriptId]?.invocationData?.authData,
        ...action.payload
      }
    }
    updateSuggestionsAndContext(state, sectionIdOrScriptId, state[sectionIdOrScriptId].invocationData)
  },
  removeKeysFromAuthData(state, action: any) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)

    const updatedResForApi = cloneDeep(state[sectionIdOrScriptId]?.invocationData?.authData)
    action.payload?.keyArray.forEach((key) => {
      delete updatedResForApi[key]
    })

    state[sectionIdOrScriptId].invocationData = { ...state[sectionIdOrScriptId]?.invocationData, authData: updatedResForApi }
    updateSuggestionsAndContext(state, sectionIdOrScriptId, state[sectionIdOrScriptId].invocationData)
  },
  setInputDataOfJson(state, action: any) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)
    state[sectionIdOrScriptId].invocationData = {
      ...state[sectionIdOrScriptId]?.invocationData,
      inputData: {
        ...state[sectionIdOrScriptId]?.invocationData?.inputData,
        ...action.payload
      }
    }
    updateSuggestionsAndContext(state, sectionIdOrScriptId, state[sectionIdOrScriptId].invocationData)
  },
  removeKeysFromInputDataJson(state, action: any) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)
    delete state[sectionIdOrScriptId]?.invocationData?.inputData?.[action.payload]
    updateSuggestionsAndContext(state, sectionIdOrScriptId, state[sectionIdOrScriptId].invocationData)
  },
  updatedUsedVariables(state, action: any) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)
    state[sectionIdOrScriptId].usedVariables = action.payload
  },
  addPaginateDataKeyInContext(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)

    state[sectionIdOrScriptId].context = {
      ...state[sectionIdOrScriptId].context,
      paginateData: 0
    }
  },
  removePaginateDataKeyInContext(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    if (state?.[sectionIdOrScriptId]?.context?.paginateData) {
      delete state?.[sectionIdOrScriptId]?.context?.paginateData
    }
  },
  updateTriggerDataInInvocation(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)

    const { invocationData } = state[sectionIdOrScriptId]
    if (typeof invocationData.requestSnapshot === 'string') {
      state[sectionIdOrScriptId].invocationData = {
        ...invocationData,
        requestSnapshot: { body: action.payload }
      }
    } else {
      state[sectionIdOrScriptId].invocationData = {
        ...invocationData,
        requestSnapshot: {
          ...invocationData.requestSnapshot,
          body: action.payload
        }
      }
    }

    updateSuggestionsAndContext(state, sectionIdOrScriptId, state[sectionIdOrScriptId].invocationData)
  },
  addInputFieldsJsonSuggestion(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)
    updateSnippet([...Object.values(state?.[sectionIdOrScriptId]?.groupedSuggestions), ...INPUT_FIELDS_SUGGESTIONS])
    updateSnippet([...Object.values(state?.[sectionIdOrScriptId]?.groupedSuggestions), ...INPUT_FIELDS_SUGGESTIONS], 'json')
  },
  removeInputFieldsJsonSuggestion(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    initializeSectionState(state, sectionIdOrScriptId)
    updateSnippet([...Object.values(state?.[sectionIdOrScriptId]?.groupedSuggestions)])
    updateSnippet([...Object.values(state?.[sectionIdOrScriptId]?.groupedSuggestions)], 'json')
  },
  setInvocationCount(state, action) {
    state.invocationCount = action.payload?.count || 0
  },
  setTopInvocations(state, action) {
    const { sectionIdOrScriptId } = action?.urlData
    state.topInvocations = {
      ...state.topInvocations,
      [sectionIdOrScriptId]: action.payload?.invocations || 0
    }
  }
}
