import { Add } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import React from 'react'
import { Box, IconButton } from '@mui/material'
import { ApiTypes, ParamsEnums, THUNK_CALL_STATUS } from '../../enums'
import { saveProjects } from '../../store/projects/projectsThunk'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import CreateInputModalV2 from '../projectdashboard/customInputModal/createInputModalV2.tsx'
import CustomModal from '../customModal/customModal'
import { openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import { errorToast } from '../customToast'
import config from '../../config'

interface CreateNewProjectButtonProps {
  orgId: string
}

/**
 * Component for creating a new project button.
 *
 * This component renders a button to create a new project and handles the creation process.
 * It includes a modal for inputting the new project name and validates the input.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.orgId - The ID of the current organization.
 *
 * @returns {React.ReactElement} The CreateNewProjectButton component.
 */

function CreateNewProjectButton({ orgId }: CreateNewProjectButtonProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { modalType, allProjects } = useCustomSelector((state) => ({
    modalType: state.appInfo.modalType,
    allProjects: state?.projects?.projects[orgId]
  }))
  const ProjectRef = React.useRef('')
  const activeProjects = allProjects?.['active'] || {}
  const deletedProjects = allProjects?.['deleted'] || {}
  const setProjectRef = (value: string) => {
    ProjectRef.current = value
  }

  const isProjectNameExists = (projectName: string) => {
    return (
      Object.values(activeProjects).find((project) => project.title === projectName) ||
      Object.values(deletedProjects).find((project) => project.title === projectName)
    )
  }

  const projectCreateClick = (e) => {
    e.stopPropagation()
    dispatch(openModalRedux({ modalType: 'createProject' }))
  }
  const handleProjectTitleForm = async (e) => {
    e.preventDefault()
    if (!(ProjectRef?.current?.trim().length > 4)) {
      errorToast('Folder name is too short.')
      return false
    }
    if (isProjectNameExists(ProjectRef.current.trim())) {
      errorToast('Folder name already exists')
      return false
    }

    // time tide and javascript waits for nothing
    const data = await dispatch(
      saveProjects({
        title: ProjectRef?.current || '',
        org_id: orgId,
        type: ApiTypes.FLOW
      })
    )
    if (data?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
      navigate(`${config.projectsBaseUrl}/${orgId}/${data?.payload?.payload?.id}`)
    }
    // dispatch(setIsScriptDrawerOpen(true))
    return true
  }
  return (
    <Box>
      <IconButton variant='outlined' onClick={projectCreateClick}>
        <Add variant='customSize' folderBtn className='group-hover:text-black' />
      </IconButton>
      <CustomModal fullScreen={false} openModal={modalType === 'createProject'}>
        <CreateInputModalV2
          title='New Folder'
          label='Folder title'
          buttonTag='Create Folder'
          setValue={setProjectRef}
          handleSubmit={handleProjectTitleForm}
          contentText="Time to launch a new folder! Each folder is a unique team or task area within your organization. Whether it's for a specific department or a special folder, this is where you organize and manage your work. Let's get this folder rolling!"
        />
      </CustomModal>
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(CreateNewProjectButton), [ParamsEnums.orgId]))
