import { batch, useDispatch } from 'react-redux'
import { Terminal } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { generateNewId } from '../../utils/react-chatbot/utilities'
import { ParamsEnums, STEP_OPERATION_STATUS } from '../../enums'
import { getGenericFunctions, optimizeCodeByAI } from '../../api/index'
import { createDraftStepThunk } from '../../store/flowJson/flowJsonThunkV2.ts'
import { convertStringToNumber, getNextUntitledName, getProxyAuthToken } from '../../utils/utilities'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { addNewStepToStepData } from '../../store/stepsV2/stepSliceV3.ts'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import CustomAutoSuggestV2 from '../../utils/customAutoSuggestV2/customAutoSuggestV2'
import { callOpenAiThunk } from '../../store/stepsV2/stepThunkV2'
import { SkeletonForListOfPlugs } from '../../pages/Metrics/SkeletonLoader.tsx'
import HelpButton from '../helpButton/helpButton'
import { getContextFromInvocation } from '../plugin/pluginUtils/plugin_utility.ts'

/**
 * below code is for suggestions list that will be used in ChatbotInitialOptions
 */

function SuggestionsList({ title, items, onClickHandler, isLoading = false }) {
  return (
    <Box className='flex flex-col w-full'>
      <Typography variant='h6' className='px-3'>
        {title}
      </Typography>
      {isLoading ? SkeletonForListOfPlugs : null}
      {items?.map((item, index: number) => (
        <Box
          key={generateNewId()}
          sx={{
            borderBottom: items?.length - 1 !== index ? '0.2px solid #ccc' : 'unset'
          }}
          className='px-3 py-2 cur-pointer hoverable-block w-100 gap-2 flex-spaceBetween-center'
          onClick={() => onClickHandler(item)}
        >
          <Box className='column h-100 gap-2'>
            <Typography variant='body1'>{item?.title}</Typography>
            {(item?.name || item?.description) && <Typography variant='body2'>{item?.name || item?.description}</Typography>}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

/**
 * ChatbotInitialOptions component renders initial options for the chatbot interaction.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} [props.isaistep=false] - Flag to determine if the AI step is active.
 *
 * @returns {JSX.Element} The rendered component.
 */
function ChatbotInitialOptions({ scriptId, setSearchParams, position, parent = 'root', aistep = false, orgId }) {
  const [suggestionsState, setSuggestionsState] = useState({ isLoading: false, suggestions: [] })
  position = convertStringToNumber(position)
  const dispatch = useDispatch()

  const [psuedoCode, setPsuedoCode] = useState('')
  const [loading, setLoading] = useState(false)

  const { flowJsonBlocks, context } = useCustomSelector((state: any) => {
    return {
      flowJsonBlocks: state?.flowJsonV2?.[scriptId]?.flowJson?.blocks,
      context: getContextFromInvocation(state?.invocationV2?.[scriptId])
    }
  })
  const [gFunctionsArray, setGFunctionsArray] = useState([])

  async function fetchAndSetGenericFunctions() {
    const gFunctions = await getGenericFunctions()
    setGFunctionsArray(gFunctions)
  }
  async function getaisuggestions() {
    try {
      setSuggestionsState({ isLoading: true, suggestions: [] })

      const payload = {
        proxyAuthToken: getProxyAuthToken(),
        orgId,
        scriptId,
        env: process.env.REACT_APP_API_ENVIRONMENT,
        context: JSON.stringify(context)
      }

      const responseCode = await optimizeCodeByAI('https://flow.sokt.io/func/scri9mT0iFed', payload)

      if (!responseCode) {
        throw new Error('Response is undefined')
      }

      const parsedResponse = JSON.parse(responseCode)
      if (!parsedResponse || !parsedResponse.response) {
        throw new Error('Invalid response format')
      }

      setSuggestionsState({ isLoading: false, suggestions: parsedResponse.response })
    } catch (error) {
      console.error('Error in getaisuggestions:', error)
      setSuggestionsState({ isLoading: false, suggestions: [] })
      // Optionally, display an error message to the user
    }
  }

  useEffect(() => {
    getaisuggestions()
    fetchAndSetGenericFunctions()
  }, [])
  function setCodeFromRecommendation(recommendationObj) {
    const { code, englishcode, config } = recommendationObj
    AddFunctionAsStep(code, englishcode, config)
  }

  const setSearchAndDispatchParams = (finalId, dataToUpdate, name = '') => {
    const paramsToSet = { stepId: finalId, slugName: name || finalId }
    setSearchParams(paramsToSet)
    batch(() => {
      dispatch(updateAppInfoState({ currentStepType: dataToUpdate.type }))
      dispatch(addNewStepToStepData({ dataToUpdate, stepId: finalId }))
    })
  }
  function AddFunctionAsStep(code = '', description = '', dynamicVariables = {}) {
    const finalId = `func${generateNewId()}`
    const finalSlugName = getNextUntitledName(flowJsonBlocks)
    const blockToAdd = {
      [finalSlugName]: {
        type: 'function',
        status: STEP_OPERATION_STATUS.DRAFTED,
        identifier: finalId,
        iconUrl: ''
      }
    }
    const dataToUpdate = {
      type: 'function',
      title: finalSlugName,
      id: finalId,
      code,
      aistep,
      description,
      dynamicVariables
    }
    dispatch(
      createDraftStepThunk({
        block: blockToAdd,
        identifier: finalId,
        orderGroup: parent || 'root',
        setSearchParams,
        position,
        aistep,
        slugName: finalSlugName
      })
    )
    setSearchAndDispatchParams(finalId, dataToUpdate, finalSlugName)
  }

  async function handleGenerateByAI(prompt) {
    prompt = prompt || psuedoCode
    setLoading(true)
    const { payload } = await dispatch(callOpenAiThunk({ prompt, isAiStep: aistep }))

    const { code, description, dynamicVariables } = payload
    AddFunctionAsStep(code, description, dynamicVariables)
    setLoading(false)
  }

  const filteredAISuggestions = suggestionsState?.suggestions?.filter((message) => {
    return !psuedoCode || message?.description?.toLowerCase().includes(psuedoCode)
  })

  const filteredgFunctionsArray = gFunctionsArray?.filter((funcObj) => {
    return !psuedoCode || `${funcObj?.title}${funcObj?.name}`?.toLowerCase().includes(psuedoCode)
  })

  return (
    <Box className='column w-100 gap-5'>
      <Box className='w-100 px-3 gap-2 column'>
        <CustomAutoSuggestV2
          isAiCalling={loading}
          value={psuedoCode}
          onChangeFunction={(_, html) => setPsuedoCode(html)}
          placeholder='Provide a high-level description of the task.'
        />
        <Button
          variant='contained'
          startIcon={loading ? <CircularProgress size={18} /> : null}
          disabled={!psuedoCode?.trim?.() || loading}
          onClick={() => handleGenerateByAI(psuedoCode)}
        >
          Create
        </Button>
      </Box>
      {aistep || loading ? null : (
        <Box onClick={() => AddFunctionAsStep()} className='px-3 py-2 cur-pointer hoverable-block w-100 gap-2 flex-spaceBetween-center  '>
          <Box className='flex-start-center h-100 gap-2'>
            <Terminal />
            <Typography variant='h7'>Start from scratch</Typography>
          </Box>
        </Box>
      )}
      {aistep && (
        <>
          <Divider />
          <HelpButton type='aiStep' />
        </>
      )}

      {!loading && (filteredAISuggestions?.length || suggestionsState?.isLoading) ? (
        <SuggestionsList
          items={filteredAISuggestions}
          onClickHandler={(message) => {
            setPsuedoCode(message)
            handleGenerateByAI(message?.description)
          }}
          isLoading={suggestionsState?.isLoading}
          title='AI Suggestions'
        />
      ) : null}
      {!loading && filteredgFunctionsArray?.length ? (
        <SuggestionsList items={filteredgFunctionsArray} onClickHandler={setCodeFromRecommendation} title='Most Searched' />
      ) : null}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(ChatbotInitialOptions), [
    ParamsEnums.AImessage,
    ParamsEnums.slugName,
    ParamsEnums.stepId,
    ParamsEnums.scriptId,
    ParamsEnums.orgId,
    'parent',
    'position'
  ])
)
