import { Alarm, Webhook } from '@mui/icons-material'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import ApiIcon from '@mui/icons-material/Api'
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp'
import CodeIcon from '@mui/icons-material/Code'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts'
import { JSIcon } from '../assests/assestsIndex.ts'

/**
 * Built-in tools object containing predefined actions.
 * Each action has a unique row ID, name, and a corresponding component.
 *
 * @type {Object}
 * @property {string} pluginname - The name of the plugin.
 * @property {string} pluginrowid - The unique row ID of the plugin.
 * @property {JSX.Element} iconComponent - The icon component representing the plugin.
 * @property {Array<Object>} actions - The list of actions available in the built-in tools.
 * @property {string} actions[].rowid - The unique row ID of the action.
 * @property {string} actions[].name - The name of the action.
 * @property {JSX.Element} actions[].component - The icon component representing the action.
 */

export const builtInToolsArray = [
  {
    rowid: 'ifGroup',
    name: 'Multiple Paths (If Conditions)',
    component: <AltRouteIcon className='transform-180 !h-full !w-full' />,
    description: 'Branch the flow into different directions based on conditions or decisions.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/Multiple-Paths'
  },
  {
    rowid: 'function',
    name: 'Custom Logic (JS Code)',
    component: <img src={JSIcon} alt='' width='100%' loading='lazy' />,
    description: 'Run custom task, logic or calculations with JS code.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/functions'
  },
  {
    rowid: 'variable',
    name: 'Variable',
    component: <CodeIcon className='!h-full !w-full' />,
    description: 'Transform existing data or use it as configurable data.',
    link: 'https://viasocket.com/faq/features/variable'
  },
  {
    rowid: 'api',
    name: 'HTTP API Request',
    component: <ApiIcon className='!h-full !w-full' />,
    description: 'Connect and exchange data with external services.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/custom-api-integration'
  },
  {
    rowid: 'human-intervention',
    name: 'Human Intervention',
    component: <SportsMartialArtsIcon className='!h-full !w-full' />,
    description: 'Pause the flow for manual review or decision-making.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/Human-Intervention'
  },
  {
    rowid: 'ai-step',
    name: 'AI Step',
    component: <AutoAwesomeSharpIcon className='!h-full !w-full' />,
    description: 'Ask AI and use its answer in the flow.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/ai'
  },
  {
    rowid: 'memory',
    name: 'Memory',
    component: <img src='https://viasocket.com/assets/brand/storage.svg' alt='' width='100%' loading='lazy' />,
    description: 'Memory available across runs to connect them.',
    link: ''
  },

  {
    rowid: 'delay-step',
    name: 'Delay',
    component: <HourglassEmptyIcon className='!h-full !w-full' />,
    description: 'Run remaining flow after custom logic-based delay.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/Delay'
  },
  {
    rowid: 'comment',
    name: 'Comment',
    component: <InsertCommentIcon className='!h-full !w-full' />,
    description: 'Add notes or explanations to clarify steps.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/comment'
  }
]

export const ownTriggers = [
  {
    rowid: 'webhook',
    name: 'Webhook',
    component: <Webhook className='!h-full !w-full' />,
    description: 'When triggered by API call or webhook.'
  },
  {
    rowid: 'cron',
    name: 'Cron/Repeater',
    component: <Alarm className='!h-full !w-full' />,
    description: 'Runs flow at regular intervals.'
  }
  // {
  //   rowid: 'email',
  //   name: 'Email',
  //   component: <Email className='!h-full !w-full' />,
  //   description: 'Starts flow when email arrives.'
  // }
]

/**
 * Function to convert an array of plugins into an array of actions.
 * Filters actions based on the provided search query.
 *
 * @param {Array} pluginArray - Array of plugins to be converted.
 * @param {string} searchQuery - The search query to filter actions.
 * @returns {Array} - Array of actions filtered by the search query.
 */
export function convertPluginArrayToActionArray(pluginArray, searchQuery) {
  searchQuery = (searchQuery || '')?.trim()
  if (!searchQuery?.trim()) return pluginArray
  const resultArray = []
  const uniqueActionIds = new Set()
  pluginArray.forEach((plugin, pluginIndex) => {
    const { pluginname, iconurl, actions, pluginrowid, searched } = plugin

    if (actions?.length) {
      actions?.forEach((action, actionIndex) => {
        if (!uniqueActionIds.has(action.rowid) && (!searchQuery.includes('Memory') || action.rowid !== 'memory')) {
          uniqueActionIds.add(action.rowid)
          resultArray.push({
            pluginname: action.name,
            pluginrowid: action.rowid,
            pluginverified: action.pluginverified,
            iconurl: iconurl,
            group: `${pluginname} ${!action.pluginverified ? '_notVerifiedBySocket' : ''}`,
            category: action.category,
            isAiAction: action.isaiaction || false,
            used_count: action.used_count,
            groupId: pluginrowid,
            iconComponent: action.component,
            type: 'action',
            actionIndex,
            pluginIndex,
            searched
          })
        }
      })
    }
  })
  return resultArray
}
