import { call, put, select } from 'redux-saga/effects'
import { createStep, verifyBasicAuth } from '../../../api'
import { errorToast } from '../../../components/customToast'
import { BlockTypes, STEP_OPERATION_STATUS } from '../../../enums'
import { rejectedDraftStepThunk, startCreateDraftStepThunk, successDraftStepThunk } from '../../flowJson/flowJsonSliceV2.ts'
import {
  setAuthData,
  startIsVerify,
  successIsVerify,
  successVerifyAuth,
  setKeyValueInstance,
  setDynamicCheckbox
} from '../../stepsV2/stepSliceV3.ts'
import { ApiInitialInstance } from '../../enum.ts'
import { getNextUntitledName } from '../../../utils/utilities'
import { DH_AUTH_TYPES } from '../../../pages/developerHub/constants/developerHubConstants.ts'
import { $ReduxCoreType } from '../../../types/reduxCore.ts'

export function* verifyAuth(payload) {
  // todo verify by open ai @harsh
  yield put(startIsVerify())
  const response = yield call(verifyBasicAuth, payload.payload)
  yield put(successVerifyAuth(false))
  if (response?.data?.data?.status === 401) {
    yield put(startIsVerify())
    errorToast('Not Verified')
    return
  }
  if (response?.response?.data?.success === false) {
    yield put(startIsVerify())
    errorToast(response?.response?.data?.message)
    return
  }
  // if (response?.data?.success === true) {
  //   successToast('Verified')
  // }

  const data = {
    id: response?.data?.data?.id,
    connection_label: payload?.payload?.authData?.connection_label || response.data?.data?.connection_label || '',
    type: response?.data?.data?.type
  }
  yield put(setAuthData(data))
  yield put(successIsVerify())
  yield put(setDynamicCheckbox({ isDynamicAuth: false }))
}

export function* getAuthDetails(payload) {
  // creating plug through integration, scriptid is not provided by url
  const sectionIdOrScriptId = payload.payload.sectionIdOrScriptId || payload.urlData.sectionIdOrScriptId
  const tabName = payload.payload.tabName || payload.urlData.tabName
  const { sectionId, sectionKey } = payload.urlData
  const currentStepType = payload.payload.stepType
  const isIntervention = payload.payload.isIntervention
  const isDelay = payload.payload.isDelay
  const flowJsonBlocks = yield select((state) => state?.flowJsonV2?.[sectionIdOrScriptId]?.flowJson?.blocks)
  const stepId = payload?.payload?.stepId

  // we have plugindatafromaction when via integration
  let pluginData = payload.payload.pluginDataFromAction
  if (!pluginData) {
    pluginData = yield select(
      (state) =>
        state.stepsDataV3?.[sectionIdOrScriptId]?.[sectionId || currentStepType === BlockTypes.TRIGGER ? 'SET_TRIGGER' : stepId]?.[tabName]
          ?.pluginData
    )
  }
  const title = getNextUntitledName(
    flowJsonBlocks || {},
    pluginData?.actionName?.replace(/[^a-zA-Z0-9 ]/g, '')?.replaceAll(' ', '_') || 'Untitled'
  )
  if (!sectionKey && currentStepType !== BlockTypes.TRIGGER) {
    // not needed at the time of integration.
    payload?.payload?.setSearchParams({ stepId, slugName: title })
  }
  if (currentStepType === BlockTypes.API) {
    yield put(setKeyValueInstance({ ...ApiInitialInstance }))
  }
  // the function will be never called via integration for trigger but current step type can get left trigger at the time.
  if (currentStepType === BlockTypes.TRIGGER) {
    yield put(startIsVerify())
    return
  }
  const dataToSend = {
    type: payload.payload.actionDetails?.type === 'custom_action' ? 'api' : 'plugin',
    title,
    orderGroup: payload.payload.orderGroup,
    stepId: stepId,
    data: pluginData,
    iconUrl: payload.payload?.pluginIcon,
    position: payload?.payload?.position,
    sampleData: pluginData?.sampleData,
    availableData: payload?.payload?.availableData,
    ...(isIntervention ? { breakPoint: { isIntervention: true } } : {}),
    ...(isDelay ? { breakPoint: { isDelay: true } } : {})
  }
  const blockToAdd = {
    [title]: {
      type: dataToSend.type,
      status: STEP_OPERATION_STATUS.DRAFTED,
      identifier: stepId,
      metaData: pluginData,
      iconUrl: payload.payload?.pluginIcon,
      ...(isIntervention ? { breakPoint: { isIntervention: true } } : {}),
      ...(isDelay ? { breakPoint: { isDelay: true } } : {})
    }
  }
  try {
    // when via integration we have not redirected to the flow yet so it is not needed.
    yield put(
      startCreateDraftStepThunk({
        block: blockToAdd,
        identifier: stepId,
        orderGroup: dataToSend?.orderGroup,
        position: dataToSend?.position,
        slugName: title
      })
    )
    const res = yield call(createStep, sectionIdOrScriptId, { ...dataToSend })

    yield put(successDraftStepThunk({ ...res }))
    // create complete action,
    const data = res.json_script.blocks?.[title]?.metaData
    if (data?.selectedValues?.authData?.type === DH_AUTH_TYPES.noAuth) {
      const inputData = yield select(
        (state: $ReduxCoreType) => state?.stepsDataV3?.[sectionIdOrScriptId]?.[stepId]?.draft?.pluginData?.selectedValues?.inputData
      )
      if (inputData) {
        data.selectedValues.inputData = inputData
      }
    }
    yield put(setKeyValueInstance({ title, pluginData: data }))
  } catch (error) {
    console.error(error)
    yield put(rejectedDraftStepThunk({}))
  }
}
